import React from "react";
import Jumbotron from "react-bootstrap/esm/Jumbotron";
// import Img from "gatsby-image"

import "./jumbotron.styles.scss";

export function IndexJumbotron() {
  return (
    <Jumbotron fluid>
      <p>index jumbotron</p>
    </Jumbotron>
  );
}

export function MastersJumbotron({ mainTitle, background }) {
  return (
    <Jumbotron
      fluid
      className="jumbotron-masters"
      style={{
        backgroundImage: `linear-gradient(
          390deg,
          rgba(28, 42, 68, .5),
          rgba(0, 0, 0, 0.2)
        ), url(${background})`,
      }}
    >
      <div className="hero-content">
        <h5>Oferta Educativa</h5>
        <h3>{mainTitle}</h3>
      </div>
    </Jumbotron>
  );
}

export function AboutUsJumbotron({ mainTitle, background }) {
  // console.log(background);
  return (
    <Jumbotron
      fluid
      className="jumbotron-about-us"
      style={{
        backgroundImage: `linear-gradient(
          390deg,
          rgba(28, 42, 68, .5),
          rgba(0, 0, 0, 0.2)
        ), url(${background})`,
      }}
    >
      <div className="hero-content">
        <h5>Conócenos mejor</h5>
        <h3>{mainTitle}</h3>
      </div>
    </Jumbotron>
  );
}
export function EventsJumbotron({ mainTitle, background }) {
  return (
    <Jumbotron
      fluid
      className="jumbotron-events"
      style={{
        backgroundImage: `linear-gradient(
          390deg,
          rgba(28, 42, 68, .5),
          rgba(0, 0, 0, 0.2)
        ), url(${background})`,
      }}
    >
      <div className="hero-content">
        <h5>Noticias</h5>
        <h3>{mainTitle}</h3>
      </div>
    </Jumbotron>
  );
}
export function DepartmentsJumbotron({ mainTitle, background }) {
  return (
    <Jumbotron
      fluid
      className="jumbotron-departments"
      style={{
        backgroundImage: `linear-gradient(
          390deg,
          rgba(28, 42, 68, .5),
          rgba(0, 0, 0, 0.2)
        ), url(${background})`,
      }}
    >
      <div className="hero-content">
        <h3>{mainTitle}</h3>
      </div>
    </Jumbotron>
  );
}
