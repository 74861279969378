import React, { useEffect } from "react";

import { Switch, Route } from "react-router-dom";

// import Helmet from "react-helmet";

import NavBar from "./components/Nav/nav";
// import Article from "../Article";
// import Categories from "../Categories";
// import Category from "../Category";
import Home from "./containers/home";
import Footer from "./components/Footer/Footer";
import AboutPage from "./containers/acerca-de";
import NotFoundPage from "./containers/404";
import DepartmentsPage from "./containers/departamentos";
import NormPage from "./containers/normatividad";
import MastersPage from "./containers/maestrias";
import BachelorsPage from "./containers/licenciaturas";
import CoursesPage from "./containers/cursos";
import CursoPage from "./containers/cursos/index";
import EventsPage from "./containers/eventos";
import PostsPage from "./containers/publicaciones";
import ContactPage from "./containers/contacto";
// import Accesories from "../../components/Accesories/Accesories";
// import Steel from "../../components/Steel/Steel";
// import Pipeline from "../../components/Pipeline/Pipeline";
// import Aluminum from "../../components/Aluminum/Aluminum";
// import CentroServicio from "../../components/CentroServicio/CentroServicio";
// import Branches from "../../components/Branches/Branches";
// import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
// import Video from "../../components/Video/Video";
// import Herrajes from "../Landings/Herrajes";
// import Tuberia from "../Landings/Tuberia";
// import Aluminio from "../Landings/Aluminio";
// import AceroInoxidable from "../Landings/AceroInoxidable";
// import Submission from "../Landings/Submission";
// import PrivacyNotice from "../Legal/PrivacyNotice";
// import ReturnPolicy from "../Legal/ReturnPolicy";
// import NewCategories from "../Categories/NewCategories";
// import ArticleNew from "../Article/ArticleNew";
// import NewCategory from "../Category/NewCategory";
// import NewArticle from "../Article/NewArticle";
// import NewArticles from "../Article/NewArticles";

// const tawkToPropertyId = '609188e3b1d5182476b5938c';
// const tawkToKey = 'e09c937c053e371796a1effe2c67f20dc9f4befe';

function App() {
  // useEffect(() => {
  //   document.addEventListener("contextmenu", (e) => {
  //     e.preventDefault();
  //   });
  //   // document.addEventListener("keyup", (e) => {
  //   //   if (e.key == "PrintScreen") {
  //   //     navigator.clipboard.writeText("");
  //   //     alert("Screenshots disabled!");
  //   //   }
  //   // });
  // }, []);
  return (
    <div className="App">
      <NavBar />
      {/*<ScrollToTop /> */}
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/acerca-de" component={AboutPage} exact />
        <Route path="/departamentos" component={DepartmentsPage} exact />
        <Route path="/normatividad" component={NormPage} exact />
        <Route path="/maestrias" component={MastersPage} exact />
        <Route path="/licenciaturas" component={BachelorsPage} exact />
        <Route path="/cursos" component={CoursesPage} exact />
        <Route path="/cursos/:codigo" component={CursoPage} exact />
        <Route path="/eventos" component={EventsPage} exact />
        <Route path="/publicaciones" component={PostsPage} exact />
        <Route path="/contacto" component={ContactPage} exact />
        {/* <Route path="/accesorios-barandales" component={Accesories} exact />
        <Route path="/acero-inoxidable" component={Steel} exact />
        <Route path="/aceroinoxidableads" component={AceroInoxidable} exact />
        <Route path="/aluminio" component={Aluminum} exact />
        <Route path="/aluminioads" component={Aluminio} exact />
        <Route path="/aviso-de-privacidad" component={PrivacyNotice} exact />
        <Route path="/blog" component={Categories} exact />
        <Route path="/blog/articulo/:id" component={Article} exact />
        <Route path="/blog/:slug" component={ArticleNew} exact />
        <Route path="/blog/categoria/:id" component={Category} exact />
        <Route path="/centro-de-servicio" component={CentroServicio} exact />
        <Route path="/gracias-:id" component={Submission} exact />
        <Route path="/herrajesparabarandalesads" component={Herrajes} exact />
        <Route path="/politicas-de-devolucion" component={ReturnPolicy} exact />
        <Route path="/sucursales" component={Branches} exact />
        <Route path="/tuberia" component={Pipeline} exact />
        <Route path="/tuberiaconduitads" component={Tuberia} exact />
        <Route path="/video" component={Video} exact />
        <Route path="/blogv2" component={NewCategories} exact />
        <Route path="/blogv2/articulos" component={NewArticles} exact />
        <Route path="/blogv2/articulo/:slug" component={NewArticle} exact /> */}
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
