import gql from "graphql-tag";

const POSTS_QUERY = gql`
  query {
    publicacione {
      Hero {
        Imagen {
          url
        }
        TituloInicial
      }
      Publicaciones {
        Sinopsis
        Boton {
          Link
          Texto
        }
        Imagen {
          url
        }
      }
    }
  }
`;

export default POSTS_QUERY;
