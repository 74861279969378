import gql from "graphql-tag";

const COURSE_QUERY = gql`
  query ($id: String!) {
    catalogoCursos(where: { Codigo: $id }) {
      Codigo
      CursoDescripcion
      CursoDescripcion2
      CursoDescripcion3
      CursoDescripcion4
      CursoIntro
      Docente
      Metodo
      Nivel
      Nombre
      Imagen {
        url
      }
    }
  }
`;

export default COURSE_QUERY;
