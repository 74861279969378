import React from "react";
// import { StaticQuery, graphql } from "gatsby";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import NavTop from "../NavTop/NavTop";

import logoUrl from "../../assets/img/logo.svg";
import toggleUrl from "../../assets/img/burger-icon.svg";

import "./nav.styles.scss";

const CustomNav = () => (
  <header>
    <div>
      <NavTop />
      <div className="nav-container">
        <Navbar variant="light" expand="lg">
          <Link to="/">
            <div className="navbar-brand">
              <img src={logoUrl} alt="ENRJSM logo" />
            </div>
          </Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav">
            <span
              class="navbar-toggler-icon"
              style={{ backgroundImage: `url(${toggleUrl})` }}
            ></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-md-auto">
              <Link to="/">inicio</Link>
              <NavDropdown title="Institución" id="nav-dropdown-1">
                <NavDropdown.Item as={Link} to="/acerca-de">
                  nosotros
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/departamentos">
                  organización escolar
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/normatividad">
                  normatividad
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Oferta educativa" id="nav-dropdown-2">
                <NavDropdown.Item as={Link} to="/maestrias">
                  maestría
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/licenciaturas">
                  licenciatura
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/cursos">
                  formación continua
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Vida Institucional" id="nav-dropdown-3">
                <NavDropdown.Item as={Link} to="/eventos">
                  eventos
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/publicaciones">
                  publicaciones
                </NavDropdown.Item>
              </NavDropdown>
              <a
                href="http://enrjsm.edu.mx/APPS/EscuelaNormal/acceso.php"
                target="_blank"
              >
                Seguimiento Escolar
              </a>
              <Link to="/contacto">contacto</Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  </header>
);

export default CustomNav;
