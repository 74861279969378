import gql from "graphql-tag";

const HOME_QUERY = gql`
  query {
    homepage {
      seo {
        id
        metaDescription
        metaTitle
      }
      hero {
        TituloInicial
        Imagen {
          url
        }
      }
      IndexCards {
        id
        descripcion
        titulo
        Link
        imagen {
          url
        }
      }
      AcercaDeIndex {
        Descripcion
        SubTitulo
        SubTitulo2
        Titulo
        id
      }
      TarjetasAcercaDe {
        Descripcion
        Titulo
        id
      }
      VideoInstitucionalLink {
        Link
        TituloVideo
      }
      LinksNavegacion {
        Link
        TextoLink
      }
    }
  }
`;

export default HOME_QUERY;
