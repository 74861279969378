import gql from "graphql-tag";

const DEGREE_QUERY = gql`
  query {
    licenciatura {
      Hero {
        TituloInicial
        Imagen {
          url
        }
      }
      LicenciaturasIntro {
        Parrafo1
        Parrafo2
        Subtitulo
        Titulo
      }
      BannerTinto {
        Titulo
        Link
        TextoBoton
        imagen {
          url
        }
      }
      LicenciaturasTabs {
        Texto
        Titulo
        TituloTab
        Boton {
          Link
          Texto
        }
      }
    }
  }
`;

export default DEGREE_QUERY;
