import React from "react";
// import { Link, graphql, useStaticQuery } from "gatsby"
import { Link } from "react-router-dom";
import { AboutUsJumbotron } from "../components/Jumbotron/Jumbotron";
import Layout from "../components/layout";
import Container from "react-bootstrap/Container";

import rightArrowShortIconUrl from "../assets/img/right-arrow--short-icon--grey.svg";
import Posts from "../components/Posts/Posts";

// Queries
import POSTS_QUERY from "../queries/posts";

import Query from "../components/Query";

const PostsPage = () => {
  // const data = useStaticQuery(query)
  // console.log(data.allStrapiPublicaciones.nodes[0])
  return (
    <Query query={POSTS_QUERY}>
      {({ data }) => {
        console.log(data);
        return (
          <>
            <AboutUsJumbotron
              mainTitle={data.publicacione.Hero.TituloInicial}
              background={data.publicacione.Hero.Imagen.url}
            />
            <div className="posts-container">
              <Container>
                <div className="about-us-info-container__site-links">
                  <Link to="/">Inicio</Link>
                  <img src={rightArrowShortIconUrl} alt="Ícono de navegación" />
                  <Link to="#" className="active">
                    Publicaciones
                  </Link>
                </div>
                <Posts posts={data.publicacione.Publicaciones} />
              </Container>
            </div>
          </>
        );
      }}
    </Query>
  );
};
// const query = graphql`
//   query {
//     allStrapiPublicaciones {
//       nodes {
//         Hero {
//           Imagen {
//             url
//           }
//           TituloInicial
//         }
//         Publicaciones {
//           Sinopsis
//           Boton {
//             Link
//             Texto
//           }
//           Imagen {
//             url
//           }
//         }
//       }
//     }
//   }
// `
export default PostsPage;
