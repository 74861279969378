import React from "react";
// import { Link, graphql, useStaticQuery } from "gatsby"
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Layout from "../components/layout";
import { AdmissionBanner } from "../components/Banners/Banners";
import { MastersJumbotron } from "../components/Jumbotron/Jumbotron";

import waLightBlueIcon from "../assets/img/wa-icon--light-blue.svg";
import fbLightBlueIcon from "../assets/img/fb-icon--light-blue.svg";
import igLightBlueIcon from "../assets/img/ig-icon--light-blue.svg";
import rightArrowShortIconUrl from "../assets/img/right-arrow--short-icon--grey.svg";
import whyUsNavTabImgUrl from "../assets/img/why-us-nav-tab.jpg";
import fileIconUrl from "../assets/img/file-icon--white.svg";

import "../scss/masters.styles.scss";
import { BlueButton } from "../components/Buttons/Buttons";

// Queries
import DEGREE_QUERY from "../queries/degrees";

import Query from "../components/Query";

const BachelorsPage = () => {
  // const data = useStaticQuery(query)
  // console.log(data.allStrapiLicenciaturas.nodes[0].LicenciaturasTabs[3])
  return (
    <Query query={DEGREE_QUERY}>
      {({ data: { licenciatura } }) => {
        // console.log(data);
        return (
          <>
            <MastersJumbotron
              mainTitle={licenciatura.Hero.TituloInicial}
              background={licenciatura.Hero.Imagen.url}
            />
            <section className="masters-container">
              <Container>
                <Row>
                  <Col xs={12} md={6} lg={7}>
                    <div className="masters-container__site-links">
                      <Link to="/">Inicio</Link>
                      <img
                        src={rightArrowShortIconUrl}
                        alt="Ícono de navegación"
                      />
                      <Link to="#" className="active">
                        Licenciatura
                      </Link>
                    </div>
                    <div className="masters-container__info">
                      <h4>{licenciatura.LicenciaturasIntro.Titulo}</h4>
                      <p>{licenciatura.LicenciaturasIntro.Parrafo1}</p>
                      <h5>{licenciatura.LicenciaturasIntro.Subtitulo}</h5>
                      <p>{licenciatura.LicenciaturasIntro.Parrafo2}</p>
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={5}>
                    <div className="masters-container__contact-card">
                      <h4>Información de contacto</h4>
                      <h5>Escuela Normal Rural “Justo Sierra Méndez”</h5>
                      <a
                        href="https://www.google.com/maps/place/Escuela+Normal+Rural+%22Justo+Sierra+M%C3%A9ndez%22/@22.001591,-102.2026659,17z/data=!3m1!4b1!4m5!3m4!1s0x8429fa5b9df2dec1:0xfbaeab62fdb7c67a!8m2!3d22.001591!4d-102.2004772"
                        target="_blank"
                      >
                        Emilio Rangel No. 208 José Ma. Morelos y Pavón.
                        Aguascalientes, CP 20320
                      </a>
                      <a href="tel:4499675049">(449) 967 5049</a>
                      <a href="mailto:contacto@enrjsm.edu.mx">
                        contacto@enrjsm.edu.mx
                      </a>
                      <p>Lun - Vie 9:00 A.M. - 5:00 P.M.</p>
                      <h3>Redes Sociales</h3>
                      <div className="d-flex">
                        {/* <a href="">
                          <img src={waLightBlueIcon} alt="" />
                        </a> */}
                        <a
                          href="https://www.facebook.com/normaljustosierra.oficial"
                          target="_blank"
                        >
                          <img src={fbLightBlueIcon} alt="" />
                        </a>
                        <a
                          href="https://www.instagram.com/normalruraljustosierramendez/"
                          target="_blank"
                        >
                          <img src={igLightBlueIcon} alt="" />
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
            <section className="masters__why-us-container">
              <Container className="masters__why-us-nav-tabs">
                <img src={whyUsNavTabImgUrl} alt="" />
                <div>
                  <Tabs
                    defaultActiveKey={
                      licenciatura.LicenciaturasTabs[0].TituloTab
                    }
                    id="uncontrolled-tab"
                  >
                    {licenciatura.LicenciaturasTabs.map((tab) => (
                      <Tab eventKey={tab.TituloTab} title={tab.TituloTab}>
                        <div>
                          <h4>{tab.Titulo}</h4>
                          <p>{tab.Texto}</p>
                          <div className="tab-buttons">
                            {tab.Boton.length !== 0 ? (
                              tab.Boton.map((btn) => (
                                <BlueButton
                                  text={btn.Texto}
                                  icon={fileIconUrl}
                                  altText="Imagen de aarchivo"
                                  linkTo={btn.Link}
                                />
                              ))
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </Tab>
                    ))}
                    {/* <Tab
                      eventKey="benefits"
                      title={
                        data.allStrapiLicenciaturas.nodes[0].LicenciaturasTabs[0]
                          .TituloTab
                      }
                    >
                      <div>
                        <h4>
                          {
                            data.allStrapiLicenciaturas.nodes[0].LicenciaturasTabs[0]
                              .Titulo
                          }
                        </h4>
                        <p>
                          {
                            data.allStrapiLicenciaturas.nodes[0].LicenciaturasTabs[0]
                              .Texto
                          }
                        </p>
                      </div>
                    </Tab>
                    <Tab
                      eventKey="info2"
                      title={
                        data.allStrapiLicenciaturas.nodes[0].LicenciaturasTabs[1]
                          .TituloTab
                      }
                    >
                      <div>
                        <h4>
                          {
                            data.allStrapiLicenciaturas.nodes[0].LicenciaturasTabs[1]
                              .Titulo
                          }
                        </h4>
                        <p>
                          {
                            data.allStrapiLicenciaturas.nodes[0].LicenciaturasTabs[1]
                              .Texto
                          }
                        </p>
                      </div>
                    </Tab>
                    <Tab
                      eventKey="info3"
                      title={
                        data.allStrapiLicenciaturas.nodes[0].LicenciaturasTabs[2]
                          .TituloTab
                      }
                    >
                      <div>
                        <h4>
                          {
                            data.allStrapiLicenciaturas.nodes[0].LicenciaturasTabs[2]
                              .Titulo
                          }
                        </h4>
                        <p>
                          {
                            data.allStrapiLicenciaturas.nodes[0].LicenciaturasTabs[2]
                              .Texto
                          }
                        </p>
                      </div>
                    </Tab> */}
                  </Tabs>
                </div>
              </Container>
            </section>

            <AdmissionBanner
              text={licenciatura.BannerTinto.Titulo}
              buttonTitle={licenciatura.BannerTinto.TextoBoton}
              buttonLink={licenciatura.BannerTinto.Link}
              bannerImage={licenciatura.BannerTinto.imagen.url}
            />
          </>
        );
      }}
    </Query>
  );
};
// const query = graphql`
//   query {
//     allStrapiLicenciaturas {
//       nodes {
//         Hero {
//           TituloInicial
//           Imagen {
//             url
//           }
//         }
//         LicenciaturasIntro {
//           Parrafo1
//           Parrafo2
//           Subtitulo
//           Titulo
//         }
//         BannerTinto {
//           Titulo
//           Link
//           TextoBoton
//           imagen {
//             url
//           }
//         }
//         LicenciaturasTabs {
//           Texto
//           Titulo
//           TituloTab
//           Boton {
//             Link
//             Texto
//           }
//         }
//       }
//     }
//   }
// `
export default BachelorsPage;
