import React, { Children } from "react";
// import Img from "gatsby-image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";

import "./customCards.styles.scss";

import rightArrow from "../../assets/img/right-arrow-icon--main-color.svg";
import schoolIcon from "../../assets/img/escuela-icon--white.svg";
import studyIcon from "../../assets/img/estudio-icon--white.svg";
import sportsIcon from "../../assets/img/deportes-icon--white.svg";
import card1IndexMiddleUrl from "../../assets/img/card-index-middle-1.jpg";
import card2IndexMiddleUrl from "../../assets/img/card-index-middle-2.jpg";
import card3IndexMiddleUrl from "../../assets/img/card-index-middle-3.jpg";
import card1IndexAbout from "../../assets/img/books-icon--green.svg";
import card2IndexAbout from "../../assets/img/diploma-icon--green.svg";
import card3IndexAbout from "../../assets/img/art-icon--green.svg";
import card4IndexAbout from "../../assets/img/school-icon--green.svg";
import aboutUsCard1Icon from "../../assets/img/about-us-card-icon--1.svg";
import aboutUsCard2Icon from "../../assets/img/about-us-card-icon--2.svg";
import aboutUsCard3Icon from "../../assets/img/about-us-card-icon--3.svg";
import aboutUsCard4Icon from "../../assets/img/policy-icon.svg";
import mailRedIcon from "../../assets/img/mail-icon--red--filled.svg";
import phoneRedIcon from "../../assets/img/phone-icon--red.svg";
import fbIconRedUrl from "../../assets/img/fb-icon--red.svg";
import igIconRedUrl from "../../assets/img/ig-icon--red.svg";
import mailRedBgIcon from "../../assets/img/mail-icon-bg--red.svg";
import phoneRedBgIcon from "../../assets/img/phone-icon-bg--red.svg";
import fbIconRedBgUrl from "../../assets/img/fb-icon-bg--red.svg";
// import { Link } from "gatsby";
import { Link } from "react-router-dom";

export const CardsBgIndex = ({ cards }) => {
  // console.log(cards[0]);
  return (
    <section className="cards-bg-index">
      <CardGroup>
        {cards.map((card) => (
          <Card key={card.id}>
            <Link to={`${card.Link}`} className="d-flex d-md-block">
              <div className="card-bg-index__header card-bg-index__header--main">
                <img src={schoolIcon} alt="Ícono de Escuela " />
                <div>
                  <h3>{card.titulo}</h3>

                  {/* <a href="">Leer más</a> */}
                </div>
              </div>
              <div className="card-bg-index__middle">
                <img src={card.imagen.url} />
                {/* <img src={card.imagen[0].url} alt="Estudios Escuela" /> */}
              </div>
              <div className="card-bg-index__body">
                <p>{card.descripcion}</p>
                {/* <a href="">
                <span>Leer más</span>{" "}
                <img src={rightArrow} alt="Leer más acerca de la escuela" />
              </a> */}
              </div>
            </Link>
          </Card>
        ))}
      </CardGroup>
      {/* <Container>
        <Row>
          <Col xs={12} lg={4}>
            <div className="card-bg-index"></div>
          </Col>
          <Col xs={12} lg={4}>
            <div className="card-bg-index">
              <div className="card-bg__header darken"></div>
              <div className="card-bg__middle"></div>
              <div className="card-bg__body"></div>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div className="card-bg-index">
              <div className="card-bg__header main dark"></div>
              <div className="card-bg__middle"></div>
              <div className="card-bg__body"></div>
            </div>
          </Col>
        </Row>
      </Container> */}
    </section>
  );
};

export const CardsAboutIndex = ({ cards }) => {
  // console.log(cards);
  const {
    Titulo: firstCardTitle,
    Descripcion: firstCardDescription,
    Image: firstImage,
  } = cards[0];
  const { Titulo: secondCardTitle, Descripcion: secondCardDescription } =
    cards[1];
  const { Titulo: thirdCardTitle, Descripcion: thirdCardDescription } =
    cards[2];
  const { Titulo: fourthCardTitle, Descripcion: fourthCardDescription } =
    cards[3];
  // console.log(fourthCardTitle);
  return (
    <div className="card-about--container container-fluid">
      <Row>
        <Card className="col-12 col-md-6 col-lg-3">
          <div className="card-about--header">
            <img
              src={card1IndexAbout}
              alt="Ícono tarjeta uno acerca de nosotros"
            />
          </div>
          <div className="card-about--body">
            <h5>{firstCardTitle}</h5>
            <p>{firstCardDescription}</p>
            {/* <a href="">Saber más</a> */}
          </div>
        </Card>
        <Card className="col-12 col-md-6 col-lg-3">
          <div className="card-about--header">
            <img
              src={card2IndexAbout}
              alt="Ícono tarjeta uno acerca de nosotros"
            />
          </div>
          <div className="card-about--body">
            <h5>{secondCardTitle}</h5>
            <p>{secondCardDescription}</p>
            {/* <a href="">Saber más</a> */}
          </div>
        </Card>
        <Card className="col-12 col-md-6 col-lg-3">
          <div className="card-about--header">
            <img
              src={card3IndexAbout}
              alt="Ícono tarjeta uno acerca de nosotros"
            />
          </div>
          <div className="card-about--body">
            <h5>{thirdCardTitle}</h5>
            <p>{thirdCardDescription}</p>
            {/* <a href="">Saber más</a> */}
          </div>
        </Card>
        <Card className="col-12 col-md-6 col-lg-3">
          <div className="card-about--header">
            <img
              src={card4IndexAbout}
              alt="Ícono tarjeta uno acerca de nosotros"
            />
          </div>
          <div className="card-about--body">
            <h5>{fourthCardTitle}</h5>
            <p>{fourthCardDescription}</p>
            {/* <a href="">Saber más</a> */}
          </div>
        </Card>
      </Row>
    </div>
  );
};

export const CardsAboutUs = ({ cards }) => {
  const { Titulo: firstCardTitle, Texto: firstCardDescription } = cards[0];
  const { Titulo: secondCardTitle, Texto: secondCardDescription } = cards[1];
  const { Titulo: thirdCardTitle, Texto: thirdCardDescription } = cards[2];
  const { Titulo: fourthCardTitle, Texto: fourthCardDescription } = cards[3];
  return (
    <section className="cards-about-us__container">
      <Row xs={1} md={3}>
        <Col>
          <Card>
            <div className="card-about-us__header">
              <img
                src={aboutUsCard1Icon}
                alt="Ícono tarjeta uno acerca de nosotros"
              />
            </div>
            <div className="card-about-us__body">
              <h5>{firstCardTitle}</h5>
              <p>{firstCardDescription}</p>
            </div>
          </Card>
        </Col>
        <Col>
          <Card>
            <div className="card-about-us__header">
              <img
                src={aboutUsCard2Icon}
                alt="Ícono tarjeta uno acerca de nosotros"
              />
            </div>
            <div className="card-about-us__body">
              <h5>{secondCardTitle}</h5>
              <p>{secondCardDescription}</p>
            </div>
          </Card>
        </Col>
        <Col>
          <Card>
            <div className="card-about-us__header">
              <img
                src={aboutUsCard4Icon}
                alt="Ícono tarjeta uno acerca de nosotros"
              />
            </div>
            <div className="card-about-us__body">
              <h5>{fourthCardTitle}</h5>
              <p>{fourthCardDescription}</p>
            </div>
          </Card>
        </Col>
        <Col>
          <Card>
            <div className="card-about-us__header">
              <img
                src={aboutUsCard3Icon}
                alt="Ícono tarjeta uno acerca de nosotros"
              />
            </div>
            <div className="card-about-us__body">
              <h5>{thirdCardTitle}</h5>
              <p>{thirdCardDescription}</p>
            </div>
          </Card>
        </Col>
      </Row>
    </section>
  );
};

export const StaffCard = ({ imgUrl, name, role, mail, phone, curriculum }) => {
  return (
    <div className="card-staff">
      <div className="card-staff__media">
        <img src={imgUrl} alt="Imagen de docente" />
      </div>
      <div className="card-staff__body">
        <h4>{name}</h4>
        <p>{role}</p>
        <div className="card-staff__body__contact">
          <div>
            <img src={mailRedIcon} alt="Ícono de correo electrónico" />
            <a href="">{mail}</a>
          </div>
          <div>
            <img src={phoneRedIcon} alt="Ícono de teléfono" />
            <a href="">{phone}</a>
          </div>
        </div>
        {/* <h5>Formación profesional</h5>
        <ul>
          {curriculum.map((cv) => (
            <li>{cv.name}</li>
          ))}
        </ul> */}
      </div>
    </div>
  );
};

export const DepartmentCard = ({ chief, department, columns }) => {
  const { Nombre: name, Email: email, Telefono: phone } = chief;
  // console.log(chief);
  return (
    <Col xs={columns} lg={columns}>
      <div className="card-department-personel">
        <h4>{department}</h4>
        <div className="card-department-personel__position">
          <span>Encargado:</span>
          <p style={{ padding: "0" }}>{name}</p>
        </div>
        <div className="card-department-personel__links">
          <div>
            <img src={mailRedIcon} alt="" />
            <a href={`mailto:${email}`}>{email}</a>
          </div>
          <div>
            <img src={phoneRedIcon} alt="" />
            <a href={`tel:${phone}`}>{phone}</a>
          </div>
          {/* <div className="card-department-personel__links__social">
            <a href="">
              <img src={fbIconRedUrl} alt="" />
            </a>
            <a href="">
              <img src={igIconRedUrl} alt="" />
            </a>
          </div> */}
        </div>
        <div className="card-department-personel__links--mobile">
          <a href={`mailto:${email}`}>
            <img src={mailRedBgIcon} alt="Redes sociales" />
          </a>
          <a href={`tel:${phone}`}>
            <img src={phoneRedBgIcon} alt="Redes Sociales" />
          </a>

          {/* <img src={fbIconRedBgUrl} alt="Redes sociales" /> */}
        </div>
      </div>
    </Col>
  );
};

export const SubDepartmentCard = ({ data, columns }) => {
  const {
    Nombre: subdepartment,
    Objetivo: description,
    jefes_departamento: chief,
  } = data;
  const { Nombre: chiefName, Email: chiefEmail, Telefono: chiefPhone } = chief;
  return (
    <Col xs={columns} lg={columns} className="mt-3">
      <div className="card-department-personel">
        <h4>{subdepartment}</h4>
        <div className="card-department-description">
          <p>{description}</p>
        </div>
        <div className="card-department-personel__position">
          <span>Encargado:</span>
          <p style={{ padding: "0" }}>{chiefName}</p>
        </div>
        <div className="card-department-personel__links">
          <div>
            <img src={mailRedIcon} alt="" />
            <a href={`mailto:${chiefEmail}`}>{chiefEmail}</a>
          </div>
          <div>
            <img src={phoneRedIcon} alt="" />
            <a href={`tel:${chiefPhone}`}>{chiefPhone}</a>
          </div>
          {/* <div className="card-department-personel__links__social">
            <a href="">
              <img src={fbIconRedUrl} alt="" />
            </a>
            <a href="">
              <img src={igIconRedUrl} alt="" />
            </a>
          </div> */}
        </div>
        <div className="card-department-personel__links--mobile">
          <a href={`mailto:${chiefEmail}`}>
            <img src={mailRedBgIcon} alt="Redes sociales" />
          </a>
          <a href={`tel:${chiefPhone}`}>
            <img src={phoneRedBgIcon} alt="Redes Sociales" />
          </a>
          {/* <img src={fbIconRedBgUrl} alt="Redes sociales" /> */}
        </div>
      </div>
    </Col>
  );
};
