import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
// import Img from "gatsby-image"

import { BlueButton, RedButtonIcon, LocationButton } from "../Buttons/Buttons";

import "./banners.styles.scss";

import admissionBannerUrl from "../../assets/img/admission-banner.jpg";
import admissionBannerBlueUrl from "../../assets/img/admission-banner-blue.jpg";
import iconToUrl from "../../assets/img/link-to-icon--white.svg";
import pdfIconUrl from "../../assets/img/pdf-icon--white.svg";
import pinIconUrl from "../../assets/img/pin-icon--white.svg";

export function AdmissionBanner({
  text,
  buttonTitle,
  buttonLink,
  bannerImage,
}) {
  return (
    <section className="banner__admission">
      <Container>
        <Row>
          <Col
            xs={12}
            md={6}
            className="banner__admission__media order-2 order-lg-1"
          >
            {bannerImage ? <img src={bannerImage} /> : <></>}
          </Col>
          <Col
            xs={12}
            md={6}
            className="banner__admission__container order-1 order-lg-2"
          >
            <h5>{text}</h5>
            <div className="banner__admission__cta ">
              {/* <BlueButton text="licenciatura" icon={iconToUrl} /> */}
              <BlueButton
                text={buttonTitle}
                linkTo={buttonLink}
                icon={pdfIconUrl}
              />
            </div>
            {/* <div className="banner__admission__cta">
              <BlueButton text="maestría" icon={iconToUrl} />
              <BlueButton text="programa educativo" icon={pdfIconUrl} />
            </div> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export function AdmissionBannerBlue({ text }) {
  return (
    <section className="banner__admission--blue">
      <Container>
        <Row>
          <Col
            xs={12}
            md={6}
            className="banner__admission__media d-flex order-2 order-lg-1"
          >
            <img src={admissionBannerBlueUrl} alt="Imagen de admisiones" />
          </Col>
          <Col
            xs={12}
            md={6}
            className="banner__admission--blue__container order-1 order-lg-2"
          >
            <h5>{text}</h5>
            <div className="banner__admission__cta ">
              <RedButtonIcon text="admisiones" icon={iconToUrl} />
              <RedButtonIcon text="programa educativo" icon={pdfIconUrl} />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export function VisitBanner() {
  return (
    <section className="banner__visit">
      <h5>Visita nuestra institución</h5>
      <p>
        Agenda una cita para conocer nuestras instalaciones y saber más de
        nuestros planes académicos.
      </p>
      <LocationButton
        text="Google Maps"
        icon={pinIconUrl}
        linkTo="https://www.google.com/maps/place/Escuela+Normal+Rural+%22Justo+Sierra+M%C3%A9ndez%22/@22.001591,-102.2026659,17z/data=!3m1!4b1!4m5!3m4!1s0x8429fa5b9df2dec1:0xfbaeab62fdb7c67a!8m2!3d22.001591!4d-102.2004772"
      />
    </section>
  );
}
