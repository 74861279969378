import React from "react"
import Post from "../Post/Post"

import "./posts.styles.scss"

export default function Posts({ posts }) {
  // console.log(posts)
  return (
    <section className="posts">
      <div className="posts__header">
        <h3>Publicaciones de la institución</h3>
      </div>
      <div className="posts__cards">
        {posts.map(({ Sinopsis, Imagen, Boton }) => (
          <Post imageUrl={Imagen.url} description={Sinopsis} button={Boton} />
        ))}
      </div>
    </section>
  )
}
