import React from "react";
// import { Link, graphql, useStaticQuery } from "gatsby"
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Layout from "../components/layout";

import { MastersJumbotron } from "../components/Jumbotron/Jumbotron";
import CourseCard from "../components/CourseCard/CourseCard";

import waLightBlueIcon from "../assets/img/wa-icon--light-blue.svg";
import fbLightBlueIcon from "../assets/img/fb-icon--light-blue.svg";
import igLightBlueIcon from "../assets/img/ig-icon--light-blue.svg";
import rightArrowShortIconUrl from "../assets/img/right-arrow--short-icon--grey.svg";
import course1ImageUrl from "../assets/img/course-img.jpg";

import { DepartmentsJumbotron } from "../components/Jumbotron/Jumbotron";
import DepartmentCollapse from "../components/DepartmentCollapse/DepartmentCollapse";

import "../scss/departments.styles.scss";

// Queries
import DEPARTMENTS_QUERY from "../queries/departamentos";

import Query from "../components/Query";

const departmentsArray = [
  {
    departmentName: "Docencia",
    departmentDescrption:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.",
    departmentChief: {
      chiefName: "Elise Beverly",
      chiefEmail: "ebeverlyl@enrhsm.edu.mx",
      chiefPhone: "(449) 123 4567",
      social: [
        { name: "facebook", link: "#" },
        {
          name: "instagram",
          link: "#",
        },
      ],
    },
    subDepartments: [
      {
        departmentName: "Psicopedagogía",
        departmentChief: {
          chiefName: "Elise Beverly",
          chiefEmail: "ebeverlyl@enrhsm.edu.mx",
          chiefPhone: "(449) 123 4567",
          social: [
            { name: "facebook", link: "#" },
            {
              name: "instagram",
              link: "#",
            },
          ],
        },
      },
      {
        departmentName: "Formación Continua",
        departmentChief: {
          chiefName: "Elise Beverly",
          chiefEmail: "ebeverlyl@enrhsm.edu.mx",
          chiefPhone: "(449) 123 4567",
          social: [
            { name: "facebook", link: "#" },
            {
              name: "instagram",
              link: "#",
            },
          ],
        },
      },
      {
        departmentName: "Idiomas",
        departmentChief: {
          chiefName: "Elise Beverly",
          chiefEmail: "ebeverlyl@enrhsm.edu.mx",
          chiefPhone: "(449) 123 4567",
          social: [
            { name: "facebook", link: "#" },
            {
              name: "instagram",
              link: "#",
            },
          ],
        },
      },
      {
        departmentName: "Posgrado",
        departmentChief: {
          chiefName: "Elise Beverly",
          chiefEmail: "ebeverlyl@enrhsm.edu.mx",
          chiefPhone: "(449) 123 4567",
          social: [
            { name: "facebook", link: "#" },
            {
              name: "instagram",
              link: "#",
            },
          ],
        },
      },
      {
        departmentName: "Vinculación",
        departmentChief: {
          chiefName: "Elise Beverly",
          chiefEmail: "ebeverlyl@enrhsm.edu.mx",
          chiefPhone: "(449) 123 4567",
          social: [
            { name: "facebook", link: "#" },
            {
              name: "instagram",
              link: "#",
            },
          ],
        },
      },
    ],
  },
  {
    departmentName: "Investigación",
    departmentDescrption:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.",
    departmentChief: {
      chiefName: "Elise Beverly",
      chiefEmail: "ebeverlyl@enrhsm.edu.mx",
      chiefPhone: "(449) 123 4567",
      social: [
        { name: "facebook", link: "#" },
        {
          name: "instagram",
          link: "#",
        },
      ],
    },
    subDepartments: [
      {
        departmentName: "Seguimiento y Evaluación",
        departmentChief: {
          chiefName: "Elise Beverly",
          chiefEmail: "ebeverlyl@enrhsm.edu.mx",
          chiefPhone: "(449) 123 4567",
          social: [
            { name: "facebook", link: "#" },
            {
              name: "instagram",
              link: "#",
            },
          ],
        },
      },
      {
        departmentName: "Cuerpos Académicos",
        departmentChief: {
          chiefName: "Elise Beverly",
          chiefEmail: "ebeverlyl@enrhsm.edu.mx",
          chiefPhone: "(449) 123 4567",
          social: [
            { name: "facebook", link: "#" },
            {
              name: "instagram",
              link: "#",
            },
          ],
        },
      },
      {
        departmentName: "Estudios Egresados",
        departmentChief: {
          chiefName: "Darlen Beverly",
          chiefEmail: "ebeverlyl@enrhsm.edu.mx",
          chiefPhone: "(449) 123 4567",
          social: [
            { name: "facebook", link: "#" },
            {
              name: "instagram",
              link: "#",
            },
          ],
        },
      },
    ],
  },
];

const DepartmentsPage = () => {
  // const data = useStaticQuery(query)
  // const departmentsArray = data.allStrapiDepartamentos.nodes
  // const subDepartmentsArray = data.allStrapiSubDepartamentos.nodes
  // const sortedDepartmentsArray = departmentsArray
  //   .slice()
  //   .sort((a, b) => (a.Prioridad > b.Prioridad ? 1 : -1))

  return (
    <Query query={DEPARTMENTS_QUERY}>
      {({ data }) => {
        console.log(data);
        const sortedDepartmentsArray = data.departamentos
          .slice()
          .sort((a, b) => (a.Prioridad > b.Prioridad ? 1 : -1));

        const subDepartmentsArray = data.subDepartamentos;
        return (
          <>
            <DepartmentsJumbotron
              mainTitle={data.departamentosPage.Hero.TituloInicial}
              background={data.departamentosPage.Hero.Imagen.url}
            />
            <Container>
              <div className="departments-container__site-links">
                <Link to="/">Inicio</Link>
                <img src={rightArrowShortIconUrl} alt="Ícono de navegación" />
                <Link to="#" className="active">
                  Departamentos
                </Link>
              </div>
            </Container>
            <section className="departments-container">
              <Container fluid>
                <h5>{data.departamentosPage.DepartamentosIntro.Titulo}</h5>
                <p>{data.departamentosPage.DepartamentosIntro.Subtitulo}</p>
                <p>{data.departamentosPage.DepartamentosIntro.Parrafo1}</p>
                <p>{data.departamentosPage.DepartamentosIntro.Parrafo2}</p>
              </Container>
              <Container fluid>
                <Row>
                  <Col xs={12} lg={8}>
                    {sortedDepartmentsArray.map((department) => (
                      <DepartmentCollapse
                        key={department.id}
                        data={department}
                        subDepartments={subDepartmentsArray}
                      />
                    ))}
                  </Col>
                  <Col xs={12} lg={4}>
                    <div className="departments-container__contact-card">
                      <h4>Información de contacto</h4>
                      <h5>Escuela Normal Rural “Justo Sierra Méndez”</h5>
                      <a
                        href="https://www.google.com/maps/place/Escuela+Normal+Rural+%22Justo+Sierra+M%C3%A9ndez%22/@22.001591,-102.2026659,17z/data=!3m1!4b1!4m5!3m4!1s0x8429fa5b9df2dec1:0xfbaeab62fdb7c67a!8m2!3d22.001591!4d-102.2004772"
                        target="_blank"
                      >
                        Emilio Rangel No. 208 José Ma. Morelos y Pavón.
                        Aguascalientes, CP 20320
                      </a>
                      <a href="tel:4499675049">(449) 967 5049</a>
                      <a href="mailto:contacto@enrjsm.edu.mx">
                        contacto@enrjsm.edu.mx
                      </a>
                      <p>Lun - Vie 8:00 A.M. - 3:00 P.M.</p>
                      <h3>Redes Sociales</h3>
                      <div className="d-flex">
                        {/* <a href="">
                          <img src={waLightBlueIcon} alt="" />
                        </a> */}
                        <a
                          href="https://www.facebook.com/normaljustosierra.oficial"
                          target="_blank"
                        >
                          <img src={fbLightBlueIcon} alt="" />
                        </a>
                        <a
                          href="https://www.instagram.com/normalruraljustosierramendez/"
                          target="_blank"
                        >
                          <img src={igLightBlueIcon} alt="" />
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </>
          // <p>departamentos</p>
        );
      }}
    </Query>
  );
};
// const query = graphql`
//   query {
//     allStrapiDepartamentosPage {
//       nodes {
//         Hero {
//           TituloInicial
//           Imagen {
//             url
//           }
//         }
//         DepartamentosIntro {
//           Parrafo1
//           Titulo
//           Parrafo2
//           Subtitulo
//         }
//       }
//     }
//     allStrapiDepartamentos {
//       nodes {
//         id
//         Descripcion
//         Nombre
//         Prioridad
//         jefes_departamento {
//           Email
//           Nombre
//           Telefono
//         }
//       }
//     }
//     allStrapiSubDepartamentos {
//       nodes {
//         id
//         jefes_departamento {
//           Email
//           Nombre
//           Telefono
//         }
//         Nombre
//         Objetivo
//         departamento {
//           Nombre
//         }
//       }
//     }
//   }
// `
export default DepartmentsPage;
