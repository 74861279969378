import gql from "graphql-tag";

const ABOUT_US_QUERY = gql`
  query {
    acercaDe {
      hero {
        TituloInicial
        Imagen {
          url
        }
      }
      aboutusinfo {
        Parrafo1
        Parrafo2
        Parrafo3
        Parrafo4
      }
      TarjetasNosotros {
        Texto
        Titulo
      }
      SecondInfo2 {
        Descripcion
        Titulo
        InfoSecundImagen {
          url
        }
      }
      SecondInfo1 {
        Descripcion
        Titulo
        InfoSecundImagen {
          url
        }
      }
      SecondInfo3 {
        Descripcion
        Titulo
        InfoSecundImagen {
          url
        }
      }
    }
  }
`;

export default ABOUT_US_QUERY;
