import gql from "graphql-tag";

const CONTACT_QUERY = gql`
  query {
    contacto {
      Hero {
        TituloInicial
        Imagen {
          url
        }
      }
      ContactoIntro {
        Titulo
        Parrafo1
        Parrafo2
        Subtitulo
      }
    }
  }
`;

export default CONTACT_QUERY;
