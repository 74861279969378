import React from "react";
// import { Link, graphql, useStaticQuery } from "gatsby"
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Layout from "../components/layout";
import { AdmissionBanner } from "../components/Banners/Banners";
import { MastersJumbotron } from "../components/Jumbotron/Jumbotron";

import waLightBlueIcon from "../assets/img/wa-icon--light-blue.svg";
import fbLightBlueIcon from "../assets/img/fb-icon--light-blue.svg";
import igLightBlueIcon from "../assets/img/ig-icon--light-blue.svg";
import rightArrowShortIconUrl from "../assets/img/right-arrow--short-icon--grey.svg";
import whyUsNavTabImgUrl from "../assets/img/why-us-nav-tab.jpg";
import whyUsNavTabImgUrl2 from "../assets/img/why-us-nav-2.jpg";

import fileIconUrl from "../assets/img/file-icon--white.svg";

import "../scss/masters.styles.scss";
import { BlueButton } from "../components/Buttons/Buttons";

// Queries
import MASTERS_QUERY from "../queries/masters";

import Query from "../components/Query";

const MastersPage = () => {
  // const data = useStaticQuery(query)
  // console.log(data.allStrapiMaestrias.nodes[0].MaestriasTabs)
  return (
    <Query query={MASTERS_QUERY}>
      {({ data: { maestria } }) => {
        // console.log(data);
        const filesDownloadArray = maestria.ArchivosDescargables;
        return (
          <>
            <MastersJumbotron
              mainTitle={maestria.Hero.TituloInicial}
              background={maestria.Hero.Imagen.url}
            />
            <section className="masters-container">
              <Container>
                <Row>
                  <Col xs={12} md={6} lg={7}>
                    <div className="masters-container__site-links">
                      <Link to="/">Inicio</Link>
                      <img
                        src={rightArrowShortIconUrl}
                        alt="Ícono de navegación"
                      />
                      <Link to="#" className="active">
                        Maestría
                      </Link>
                    </div>
                    <div className="masters-container__info">
                      <h4>{maestria.MaestriasIntro.Titulo}</h4>
                      <p>{maestria.MaestriasIntro.Parrafo1}</p>
                      <h5>{maestria.MaestriasIntro.Subtitulo}</h5>
                      <h4>Características del programa</h4>
                      <p>{maestria.MaestriasIntro.Parrafo2}</p>
                      <h4>Archivos descargables</h4>
                      <div className="files">
                        {filesDownloadArray.map((file) => (
                          <BlueButton
                            text={file.Texto}
                            linkTo={file.Link}
                            icon={fileIconUrl}
                          />
                        ))}
                      </div>
                      {/* <p>Programa educativo</p>
                      <p>convocatoria</p>
                      <p>lineas de investigación</p>
                      <p>lineamentos para pago de colegiaturas</p>
                      <p>carta compromiso</p>
                      <p>alumnos aceptados</p> */}
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={5}>
                    <div className="masters-container__contact-card">
                      <h4>Información de contacto</h4>
                      <h5>Departamento de posgrados</h5>
                      <a
                        href="https://www.google.com/maps/place/Escuela+Normal+Rural+%22Justo+Sierra+M%C3%A9ndez%22/@22.001591,-102.2026659,17z/data=!3m1!4b1!4m5!3m4!1s0x8429fa5b9df2dec1:0xfbaeab62fdb7c67a!8m2!3d22.001591!4d-102.2004772"
                        target="_blank"
                      >
                        Emilio Rangel No. 208 José Ma. Morelos y Pavón.
                        Aguascalientes, CP 20320
                      </a>
                      <a href="tel:4499675049">(449) 967 5049</a>
                      <a href="mailto:posgrados@enrjsm.edu.mx">
                        posgrados@enrjsm.edu.mx
                      </a>
                      <p>Sáb. 8:00 A.M. - 2:00 P.M.</p>
                      <h3>Redes Sociales</h3>
                      <div className="d-flex">
                        {/* <a href="">
                          <img src={waLightBlueIcon} alt="" />
                        </a> */}
                        <a
                          href="https://www.facebook.com/normaljustosierra.oficial"
                          target="_blank"
                        >
                          <img src={fbLightBlueIcon} alt="" />
                        </a>
                        <a
                          href="https://www.instagram.com/normalruraljustosierramendez/"
                          target="_blank"
                        >
                          <img src={igLightBlueIcon} alt="" />
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
            <section className="masters__why-us-container">
              <Container className="masters__why-us-nav-tabs">
                <img src={whyUsNavTabImgUrl} alt="" />
                <div>
                  <Tabs
                    defaultActiveKey={maestria.MaestriasTabs[0].TituloTab}
                    id="uncontrolled-tab"
                  >
                    {maestria.MaestriasTabs.map((tab) => (
                      <Tab eventKey={tab.TituloTab} title={tab.TituloTab}>
                        <div>
                          <h4>{tab.Titulo}</h4>
                          <p>{tab.Texto}</p>
                        </div>
                      </Tab>
                    ))}
                    {/* <Tab
                      eventKey="benefits"
                      title={
                        data.allStrapiMaestrias.nodes[0].MaestriasTabs[0].TituloTab
                      }
                    >
                      <div>
                        <h4>
                          {data.allStrapiMaestrias.nodes[0].MaestriasTabs[0].Titulo}
                        </h4>
                        <p>
                          {data.allStrapiMaestrias.nodes[0].MaestriasTabs[0].Texto}
                        </p>
                      </div>
                    </Tab>
                    <Tab
                      eventKey="info2"
                      title={
                        data.allStrapiMaestrias.nodes[0].MaestriasTabs[1].TituloTab
                      }
                    >
                      <div>
                        <h4>
                          {data.allStrapiMaestrias.nodes[0].MaestriasTabs[1].Titulo}
                        </h4>
                        <p>
                          {data.allStrapiMaestrias.nodes[0].MaestriasTabs[1].Texto}
                        </p>
                      </div>
                    </Tab>
                    <Tab
                      eventKey="info3"
                      title={
                        data.allStrapiMaestrias.nodes[0].MaestriasTabs[2].TituloTab
                      }
                    >
                      <div>
                        <h4>
                          {data.allStrapiMaestrias.nodes[0].MaestriasTabs[2].Titulo}
                        </h4>
                        <p>
                          {data.allStrapiMaestrias.nodes[0].MaestriasTabs[2].Texto}
                        </p>
                      </div>
                    </Tab> */}
                  </Tabs>
                </div>
              </Container>
            </section>
            <AdmissionBanner
              text={maestria.BannerTinto.Titulo}
              buttonTitle={maestria.BannerTinto.TextoBoton}
              buttonLink={maestria.BannerTinto.Link}
              bannerImage={maestria.BannerTinto.imagen.url}
            />
          </>
        );
      }}
    </Query>
  );
};
// const query = graphql`
//   query {
//     allStrapiMaestrias {
//       nodes {
//         Hero {
//           TituloInicial
//           Imagen {
//             url
//           }
//         }
//         MaestriasIntro {
//           Parrafo1
//           Parrafo2
//           Subtitulo
//           Titulo
//         }
//         BannerTinto {
//           Titulo
//           Link
//           TextoBoton
//           imagen {
//             url
//           }
//         }
//         MaestriasTabs {
//           Texto
//           Titulo
//           TituloTab
//         }
//         ArchivosDescargables {
//           Link
//           Texto
//         }
//       }
//     }
//   }
// `
export default MastersPage;
