import gql from "graphql-tag";

const NORMS_QUERY = gql`
  query {
    normatividad {
      Hero {
        TituloInicial
        Imagen {
          url
        }
      }
      NormatividadTab {
        Prioridad
        Texto
        Titulo
        TituloTab
        Boton {
          Link
          Texto
        }
      }
    }
    normatividadBotones {
      Link
      TextoBoton
      Prioridad
    }
  }
`;

export default NORMS_QUERY;
