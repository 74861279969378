import React from "react";
// import { Link, graphql, useStaticQuery } from "gatsby";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Layout from "../components/layout";

import { MastersJumbotron } from "../components/Jumbotron/Jumbotron";
import CourseCard from "../components/CourseCard/CourseCard";
import ReactMarkdown from "react-markdown";

import waLightBlueIcon from "../assets/img/wa-icon--light-blue.svg";
import fbLightBlueIcon from "../assets/img/fb-icon--light-blue.svg";
import igLightBlueIcon from "../assets/img/ig-icon--light-blue.svg";
import rightArrowShortIconUrl from "../assets/img/right-arrow--short-icon--grey.svg";

import course1ImageUrl from "../assets/img/course-img.jpg";

import "../scss/courses.styles.scss";

// Queries
import COURSES_QUERY from "../queries/courses";

import Query from "../components/Query";

const CoursesPage = () => {
  // const data = useStaticQuery(query)
  // console.log(data.allStrapiCursos.nodes[0].CursosIntro.Parrafo1)

  return (
    <Query query={COURSES_QUERY}>
      {({ data }) => {
        // const coursesArray = data.allStrapiCatalogoCursos.nodes
        // console.log(data);
        const coursesArray = data.catalogoCursos;
        return (
          <>
            <MastersJumbotron
              mainTitle={data.curso.Hero.TituloInicial}
              background={data.curso.Hero.Imagen.url}
            />
            <section className="courses-info-container">
              <Container>
                <Row>
                  <Col xs={12} md={6} lg={7}>
                    <div className="courses-info-container__site-links">
                      <Link to="/">Inicio</Link>
                      <img
                        src={rightArrowShortIconUrl}
                        alt="Ícono de navegación"
                      />
                      <Link to="#" className="active">
                        Cursos
                      </Link>
                    </div>
                    <div className="courses-info-container__info">
                      <h4>{data.curso.Titulo}</h4>
                      <ReactMarkdown>{data.curso.Parrafo1}</ReactMarkdown>

                      <h5>{data.curso.Subtitulo}</h5>
                      {data.curso.Parrafo2}
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={5}>
                    <div className="courses-info-container__contact-card">
                      <h4>Información de contacto</h4>
                      <h5>Escuela Normal Rural “Justo Sierra Méndez”</h5>
                      <a
                        href="https://www.google.com/maps/place/Escuela+Normal+Rural+%22Justo+Sierra+M%C3%A9ndez%22/@22.001591,-102.2026659,17z/data=!3m1!4b1!4m5!3m4!1s0x8429fa5b9df2dec1:0xfbaeab62fdb7c67a!8m2!3d22.001591!4d-102.2004772"
                        target="_blank"
                      >
                        Emilio Rangel No. 208 José Ma. Morelos y Pavón.
                        Aguascalientes, CP 20320
                      </a>
                      <a href="tel:4499675049">(449) 967 5049</a>
                      <a href="mailto:contacto@enrjsm.edu.mx">
                        contacto@enrjsm.edu.mx
                      </a>
                      <p>Lun - Vie 9:00 A.M. - 5:00 P.M.</p>
                      <h3>Redes Sociales</h3>
                      <div>
                        {/* <a href="">
                          <img src={waLightBlueIcon} alt="" />
                        </a> */}
                        <a
                          href="https://www.facebook.com/normaljustosierra.oficial"
                          target="_blank"
                        >
                          <img src={fbLightBlueIcon} alt="" />
                        </a>
                        <a
                          href="https://www.instagram.com/normalruraljustosierramendez/"
                          target="_blank"
                        >
                          <img src={igLightBlueIcon} alt="" />
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
            <section className="courses-container">
              {coursesArray.map(
                ({ id, Imagen, Nombre, Codigo, Nivel, Docente }) => (
                  <CourseCard
                    key={id}
                    imageUrl={course1ImageUrl}
                    name={Nombre}
                    code={Codigo}
                    level={Nivel}
                    teacher={Docente}
                    courseImage={Imagen.url}
                  />
                )
              )}
            </section>
          </>
        );
      }}
    </Query>
  );
};
// const query = graphql`
//   query {
//     allStrapiCursos {
//       nodes {
//         Hero {
//           TituloInicial
//           Imagen {
//             url
//           }
//         }
//         Parrafo1
//         Parrafo2
//         Subtitulo
//         Titulo
//       }
//     }
//     allStrapiCatalogoCursos {
//       nodes {
//         Codigo
//         CursoDescripcion
//         CursoDescripcion2
//         CursoDescripcion3
//         CursoDescripcion4
//         CursoIntro
//         Docente
//         Metodo
//         Nivel
//         Nombre
//         Semestre
//         id
//         Imagen {
//           url
//         }
//       }
//     }
//   }
// `
export default CoursesPage;
