import React from "react";
import Layout from "../../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { Link, graphql } from "gatsby"
import { Link } from "react-router-dom";
import { useParams } from "react-router";

import rightArrowShortIconUrl from "../../assets/img/right-arrow--short-icon--grey.svg";
import course1ImageUrl from "../../assets/img/course-img.jpg";
import waLightBlueIcon from "../../assets/img/wa-icon--light-blue.svg";
import fbLightBlueIcon from "../../assets/img/fb-icon--light-blue.svg";
import igLightBlueIcon from "../../assets/img/ig-icon--light-blue.svg";

import "../../scss/course.styles.scss";
import { AdmissionBannerBlue } from "../../components/Banners/Banners";

// Queries
import COURSE_QUERY from "../../queries/course";

import Query from "../../components/Query";

const CursoPage = () => {
  let { codigo } = useParams();
  console.log(codigo);
  // const {
  //   Codigo: courseCode,
  //   CursoDescripcion: courseDescription,
  //   CursoDescripcion2: courseDescription2,
  //   CursoDescripcion3: courseDescription3,
  //   CursoDescripcion4: courseDescription4,
  //   CursoIntro: courseIntro,
  //   Docente: courseTeacher,
  //   Metodo: courseMethod,
  //   Nivel: courseLevel,
  //   Nombre: courseName,
  //   Semestre: courseSemester,
  //   Imagen: courseImage,
  // } = data.strapiCatalogoCursos
  // console.log(data.strapiCatalogoCursos)
  return (
    <Query query={COURSE_QUERY} id={codigo}>
      {({ data }) => {
        const courseData = data.catalogoCursos[0];
        console.log(courseData);
        const {
          Codigo: courseCode,
          CursoDescripcion: courseDescription,
          CursoDescripcion2: courseDescription2,
          CursoDescripcion3: courseDescription3,
          CursoDescripcion4: courseDescription4,
          CursoIntro: courseIntro,
          Docente: courseTeacher,
          Metodo: courseMethod,
          Nivel: courseLevel,
          Nombre: courseName,
          Imagen: courseImage,
        } = courseData;
        return (
          <>
            <div className="course-container">
              <div className="course-container__site-links">
                <h5>{courseCode}</h5>
                <h3>{courseName}</h3>
                <Link to="/">Inicio</Link>
                <img src={rightArrowShortIconUrl} alt="Ícono de navegación" />
                <Link to="/cursos">Cursos</Link>
                <img src={rightArrowShortIconUrl} alt="Ícono de navegación" />
                <Link to="#" className="active">
                  {courseName}
                </Link>
              </div>
              <div className="course-container__info">
                <Container fluid>
                  <Row>
                    <Col xs={12} md={5}>
                      <img
                        src={courseImage.url}
                        className="img-fluid"
                        alt="Imagen del curso"
                      />
                    </Col>
                    <Col xs={12} md={7}>
                      <div className="course-container__summary">
                        <div>
                          <p>
                            <span>Docente:</span>
                            {courseTeacher}
                          </p>
                        </div>
                        <div>
                          <p>
                            <span>Nivel:</span>
                            {courseLevel}
                          </p>
                        </div>
                        <div>
                          <p>
                            <span>Método:</span>
                            {courseMethod}
                          </p>
                        </div>
                      </div>
                      <div className="course-container__description">
                        <h5>{courseIntro}</h5>
                        <p>{courseDescription}</p>
                        {/* <ul>
                          <li>Enseñanza al estudiante</li>
                          <li>Sed ut perspiciatis</li>
                          <li>Sed ut perspiciatis</li>
                          <li>Sed ut perspiciatis</li>
                        </ul> */}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              {/* <AdmissionBannerBlue /> */}
              {/* <Container className="mb-5">
                <Row>
                  <Col xs={12} lg={5}>
                    <div className="course-container__contact-card">
                      <h4>Información de contacto</h4>
                      <h5>Departamento de difusión cultural</h5>
                      <a
                        href="https://www.google.com/maps/place/Escuela+Normal+Rural+%22Justo+Sierra+M%C3%A9ndez%22/@22.001591,-102.2026659,17z/data=!3m1!4b1!4m5!3m4!1s0x8429fa5b9df2dec1:0xfbaeab62fdb7c67a!8m2!3d22.001591!4d-102.2004772"
                        target="_blank"
                      >
                        Emilio Rangel No. 208 José Ma. Morelos y Pavón.
                        Aguascalientes, CP 20320
                      </a>
                      <a href="tel:4499675049">(449) 967 5049</a>
                      <a href="mailto:difusioncultural@enrjsm.edu.mx">
                        difusioncultural@enrjsm.edu.mx
                      </a>
                      <p>Lun - Vie 9:00 A.M. - 5:00 P.M.</p>
                      <h3>Redes Sociales</h3>
                      <div>
                        <a
                          href="https://www.facebook.com/normaljustosierra.oficial"
                          target="_blank"
                        >
                          <img src={fbLightBlueIcon} alt="" />
                        </a>
                        <a
                          href="https://www.instagram.com/normalruraljustosierramendez/"
                          target="_blank"
                        >
                          <img src={igLightBlueIcon} alt="" />
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} lg={7}>
                    <div className="course-container__second-info">
                      <p>{courseDescription2}</p>
                      <p>{courseDescription3}</p>
                      <p>{courseDescription4}</p>
                    </div>
                  </Col>
                </Row>
              </Container> */}
            </div>
          </>
        );
      }}
    </Query>
  );
};

// export const query = graphql`
//   query CourseQuery($Codigo: String) {
//     strapiCatalogoCursos(Codigo: { eq: $Codigo }) {
//       Codigo
//       CursoDescripcion
//       CursoDescripcion2
//       CursoDescripcion3
//       CursoDescripcion4
//       CursoIntro
//       Docente
//       Metodo
//       Nivel
//       Nombre
//       Semestre
//       Imagen {
//         url
//       }
//     }
//   }
// `

export default CursoPage;
