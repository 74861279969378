import gql from "graphql-tag";

const COURSES_QUERY = gql`
  query {
    curso {
      Hero {
        TituloInicial
        Imagen {
          url
        }
      }
      Parrafo1
      Parrafo2
      Subtitulo
      Titulo
    }
    catalogoCursos {
      Codigo
      CursoDescripcion
      CursoDescripcion2
      CursoDescripcion3
      CursoDescripcion4
      CursoIntro
      Docente
      Metodo
      Nivel
      Nombre
      id
      Imagen {
        url
      }
    }
  }
`;

export default COURSES_QUERY;
