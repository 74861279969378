import React from "react";
// import { Link, graphql, useStaticQuery } from "gatsby"
import { Link } from "react-router-dom";
// import Img from "gatsby-image"
// import "bootstrap/dist/css/bootstrap.min.css";
// import "../assets/scss/main.scss";
import "../scss/aboutUs.styles.scss";

// import Layout from "../components/layout"
// import Jumbotron from "react-bootstrap/Jumbotron"
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { AboutUsJumbotron } from "../components/Jumbotron/Jumbotron";

import rightArrowShortIconUrl from "../assets/img/right-arrow--short-icon--grey.svg";
import classroomImgUrl from "../assets/img/classroom.jpg";

import videoPlaceholderUrl from "../assets/img/video-placeholder.jpg";
import linkIconUrl from "../assets/img/link-icon--white.svg";
import rightArrowIconUrl from "../assets/img/right-arrow-icon--white.svg";

import { WhiteButton } from "../components/Buttons/Buttons";
import {
  CardsAboutIndex,
  CardsAboutUs,
  CardsBgIndex,
} from "../components/CustomCards/CustomCards";
import { AdmissionBanner, VisitBanner } from "../components/Banners/Banners";
// import Events from "../../containers/Events/Events";
import Query from "../components/Query";

// Queries
import ABOUT_US_QUERY from "../queries/about-us";

const AboutPage = () => {
  // const data = useStaticQuery(query);
  // console.log(data.allStrapiAcercaDe.nodes[0]);
  return (
    <Query query={ABOUT_US_QUERY}>
      {({ data: { acercaDe } }) => {
        console.log(acercaDe);
        return (
          <>
            <AboutUsJumbotron
              mainTitle={acercaDe.hero.TituloInicial}
              background={acercaDe.hero.Imagen.url}
            />
            <section className="about-us-container">
              <Container>
                <Row>
                  <Col xs={12} lg={4}>
                    <div className="about-us-info-container__site-links">
                      <Link to="/">Inicio</Link>
                      <img
                        src={rightArrowShortIconUrl}
                        alt="Ícono de navegación"
                      />
                      <Link to="#" className="active">
                        Nosotros
                      </Link>
                    </div>
                    <div className="about-us-info-container__heading">
                      <h3>Nuestra historia</h3>
                    </div>
                  </Col>
                  <Col xs={12} lg={8}>
                    <Row>
                      <Col xs lg={6} className="about-us-info-container__info">
                        <p>{acercaDe.aboutusinfo.Parrafo1}</p>
                        <p>{acercaDe.aboutusinfo.Parrafo2}</p>
                      </Col>
                      <Col xs lg={6} className="about-us-info-container__info">
                        <p>{acercaDe.aboutusinfo.Parrafo3}</p>
                        <p>{acercaDe.aboutusinfo.Parrafo4}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </section>
            <CardsAboutUs cards={acercaDe.TarjetasNosotros} />
            <section className="about-us-secondary-info">
              <Container fluid>
                <Row>
                  <Col
                    xs={12}
                    lg={6}
                    className="about-us-secondary-info__image"
                  >
                    <img
                      src={acercaDe.SecondInfo1.InfoSecundImagen.url}
                      className="img-fluid"
                    />
                    {/* <img src={classroomImgUrl} alt="Aula de clases" /> */}
                  </Col>
                  <Col
                    xs={12}
                    lg={6}
                    className="about-us-secondary-info__content"
                  >
                    <h4>{acercaDe.SecondInfo1.Titulo}</h4>
                    <p>{acercaDe.SecondInfo1.Descripcion}</p>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={12}
                    lg={6}
                    className="about-us-secondary-info__content"
                  >
                    <h4>{acercaDe.SecondInfo2.Titulo}</h4>
                    <p>{acercaDe.SecondInfo2.Descripcion}</p>
                  </Col>
                  <Col
                    xs={12}
                    lg={6}
                    className="about-us-secondary-info__image d-none d-lg-block"
                  >
                    <img
                      src={acercaDe.SecondInfo2.InfoSecundImagen.url}
                      className="img-fluid"
                    />{" "}
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={12}
                    lg={6}
                    className="about-us-secondary-info__image"
                  >
                    <img
                      src={acercaDe.SecondInfo3.InfoSecundImagen.url}
                      className="img-fluid"
                    />{" "}
                  </Col>
                  <Col
                    xs={12}
                    lg={6}
                    className="about-us-secondary-info__content"
                  >
                    <h4>{acercaDe.SecondInfo3.Titulo}</h4>
                    <p>{acercaDe.SecondInfo3.Descripcion}</p>
                  </Col>
                </Row>
              </Container>
            </section>
          </>
        );
      }}
    </Query>
  );
};

// const query = graphql`
//   query {
//     allStrapiAcercaDe {
//       nodes {
//         hero {
//           TituloInicial
//           Imagen {
//             url
//           }
//         }
//         aboutusinfo {
//           Parrafo1
//           Parrafo2
//           Parrafo3
//           Parrafo4
//         }
//         TarjetasNosotros {
//           Texto
//           Titulo
//         }
//         SecondInfo2 {
//           Descripcion
//           Titulo
//           InfoSecundImagen {
//             url
//           }
//         }
//         SecondInfo1 {
//           Descripcion
//           Titulo
//           InfoSecundImagen {
//             url
//           }
//         }
//         SecondInfo3 {
//           Descripcion
//           Titulo
//           InfoSecundImagen {
//             url
//           }
//         }
//       }
//     }
//   }
// `

export default AboutPage;
