import React from "react";
// import Layout from "../components/layout"
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
// import { Link, graphql, useStaticQuery } from "gatsby"
import { Link } from "react-router-dom";

import rightArrowShortIconUrl from "../assets/img/right-arrow--short-icon--grey.svg";
import staff1ImageUrl from "../assets/img/docente-1.jpg";
import staff2ImageUrl from "../assets/img/docente-2.jpg";
import staff3ImageUrl from "../assets/img/docente-3.jpg";
import waLightBlueIcon from "../assets/img/wa-icon--light-blue.svg";
import fbLightBlueIcon from "../assets/img/fb-icon--light-blue.svg";
import igLightBlueIcon from "../assets/img/ig-icon--light-blue.svg";

import fileIconUrl from "../assets/img/file-icon--white.svg";

import whyUsNavTabImgUrl from "../assets/img/why-us-nav-tab.jpg";

import { StaffCard } from "../components/CustomCards/CustomCards";
import { AboutUsJumbotron } from "../components/Jumbotron/Jumbotron";

import "../scss/staff.styles.scss";
import { BlueButton } from "../components/Buttons/Buttons";

// Queries
import NORMS_QUERY from "../queries/norms";

import Query from "../components/Query";

const NormPage = () => {
  // const data = useStaticQuery(query)

  // const buttonsArray = data.allStrapiNormatividadBotone.nodes
  // const sortedButtonsArray = buttonsArray
  //   .slice()
  //   .sort((a, b) => (a.Prioridad > b.Prioridad ? 1 : -1))
  // console.log(sortedTabsArray)
  return (
    <Query query={NORMS_QUERY}>
      {({ data }) => {
        console.log(data);
        const tabsArray = data.normatividad.NormatividadTab;
        const sortedTabsArray = tabsArray
          .slice()
          .sort((a, b) => (a.Prioridad > b.Prioridad ? 1 : -1));
        return (
          <>
            <AboutUsJumbotron
              mainTitle={data.normatividad.Hero.TituloInicial}
              background={data.normatividad.Hero.Imagen.url}
            />
            <Container>
              <div className="staff-container__site-links">
                <Link to="/">Inicio</Link>
                <img src={rightArrowShortIconUrl} alt="Ícono de navegación" />
                <Link to="#" className="active">
                  Normatividad
                </Link>
              </div>
            </Container>
            <div className="norm-container">
              <Container fluid>
                <Row>
                  <Col xs={12} lg={8}>
                    <div className="norm-nav-tabs">
                      {/* <div className="norm-nav-tabs__media">
                      <img src={whyUsNavTabImgUrl} alt="" />
                    </div> */}
                      <div className="norm-nav-tabs__container">
                        <Tabs
                          defaultActiveKey={sortedTabsArray[0].TituloTab}
                          id="uncontrolled-tab"
                        >
                          {sortedTabsArray.map((tab) => (
                            <Tab eventKey={tab.TituloTab} title={tab.TituloTab}>
                              <div>
                                <h4>{tab.Titulo}</h4>
                                <p>{tab.Texto}</p>
                                <div className="tab-buttons">
                                  {tab.Boton.length !== 0 ? (
                                    tab.Boton.map((btn) => (
                                      <BlueButton
                                        text={btn.Texto}
                                        icon={fileIconUrl}
                                        altText="Imagen de archivo"
                                        linkTo={btn.Link}
                                      />
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </Tab>
                          ))}
                        </Tabs>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} lg={4}>
                    <div className="course-container__contact-card mt-4 mt-lg-0">
                      <h4>Información de contacto</h4>
                      <h5>Escuela Normal Rural “Justo Sierra Méndez”</h5>
                      <a
                        href="https://www.google.com/maps/place/Escuela+Normal+Rural+%22Justo+Sierra+M%C3%A9ndez%22/@22.001591,-102.2026659,17z/data=!3m1!4b1!4m5!3m4!1s0x8429fa5b9df2dec1:0xfbaeab62fdb7c67a!8m2!3d22.001591!4d-102.2004772"
                        target="_blank"
                      >
                        Emilio Rangel No. 208 José Ma. Morelos y Pavón.
                        Aguascalientes, CP 20320
                      </a>
                      <a href="tel:4499675049">(449) 967 5049</a>
                      <a href="mailto:contacto@enrjsm.edu.mx">
                        contacto@enrjsm.edu.mx
                      </a>
                      <p>Lun - Vie 9:00 A.M. - 5:00 P.M.</p>
                      <h3>Redes Sociales</h3>
                      <div className="d-flex">
                        {/* <a href="">
                        <img src={waLightBlueIcon} alt="" />
                      </a> */}
                        <a
                          href="https://www.facebook.com/normaljustosierra.oficial"
                          target="_blank"
                        >
                          <img src={fbLightBlueIcon} alt="" />
                        </a>
                        <a
                          href="https://www.instagram.com/normalruraljustosierramendez/"
                          target="_blank"
                        >
                          <img src={igLightBlueIcon} alt="" />
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </>
        );
      }}
    </Query>
  );
};
// export const query = graphql`
//   {
//     allStrapiNormatividad {
//       nodes {
//         Hero {
//           TituloInicial
//           Imagen {
//             url
//           }
//         }
//         NormatividadTab {
//           Prioridad
//           Texto
//           Titulo
//           TituloTab
//           Boton {
//             Link
//             Texto
//           }
//         }
//       }
//     }
//     allStrapiNormatividadBotone {
//       nodes {
//         Link
//         TextoBoton
//         Prioridad
//       }
//     }
//   }
// `
export default NormPage;
