import React from "react";
// import Img from "gatsby-image"

import "./event.styles.scss";

// import event1ImageUrl from "../../assets/event-1.jpg";
// import event2ImageUrl from "../../assets/event-2.jpg";
// import event3ImageUrl from "../../assets/event-3.jpg";
import eventTimeIconUrl from "../../assets/img/event-time-icon--red.svg";
import eventLocationIconUrl from "../../assets/img/event-location-icon--red.svg";

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export default function Event({ imageUrl, name, date, time, location }) {
  return (
    <div className="card-event">
      <div className="card-event__header">
        {/* <img src={imageUrl} alt="Evento 1 de la institución" /> */}
        <img src={imageUrl.url} className="img-fluid" />
      </div>
      <div className="card-event__body">
        <div className="d-flex align-items-center">
          <div className="card-event__body__date">
            <h3>{formatDate(date)}</h3>
            <div className="line--red"></div>
          </div>
          <div className="card-event__body__info">
            <h4 className="event-name">{name}</h4>
            {/* <div className="card-event__body__time">
            <div>
              <img src={eventTimeIconUrl} alt="" />
            </div>
            <p>{time}</p>
          </div> */}
          </div>
        </div>
        <div className="card-event__body__location">
          <div>
            <img src={eventLocationIconUrl} alt="" />
          </div>
          <a href={location} target="_blank">
            {location}
          </a>
        </div>
      </div>
    </div>
  );
}
