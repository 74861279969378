import React from "react";

import mailWhiteIcon from "../../assets/img/mail-icon--white.svg";
import phoneWhiteIcon from "../../assets/img/phone-icon--white.svg";
import waWhiteIcon from "../../assets/img/wa-icon--white.svg";
import fbWhiteIcon from "../../assets/img/fb-icon--white.svg";
import igWhiteIcon from "../../assets/img/ig-icon--white.svg";

import "./navTop.styles.scss";

const NavTop = () => {
  return (
    <div className="navbar-top">
      <div className="navbar-top--l">
        <div className="navbar-top--email">
          <img src={mailWhiteIcon} alt="" />
          <a href="mailto:difusioncultural@enrjsm.edu.mx">
            contacto@enrjsm.edu.mx
          </a>
        </div>
        <div className="navbar-top--phone d-none d-md-block">
          <img src={phoneWhiteIcon} alt="" />
          <a href="tel:4499675049">(449) 967 5049</a>
        </div>
      </div>
      <div className="navbar-top--social">
        {/* <a href="">
          <img src={waWhiteIcon} alt="" />
        </a> */}
        <a
          href="https://www.facebook.com/normaljustosierra.oficial"
          target="_blank"
        >
          <img src={fbWhiteIcon} alt="" />
        </a>
        <a
          href="https://www.instagram.com/normalruraljustosierramendez/"
          target="_blank"
        >
          <img src={igWhiteIcon} alt="" />
        </a>
      </div>
    </div>
  );
};
export default NavTop;
