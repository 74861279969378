import React from "react";
// import Layout from "../components/layout"
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
// import { Link, graphql, useStaticQuery } from "gatsby"
import { Link } from "react-router-dom";
// import Img from "gatsby-image"

import event1ImageUrl from "../assets/img/event-1.jpg";
import event2ImageUrl from "../assets/img/event-2.jpg";
import event3ImageUrl from "../assets/img/event-3.jpg";
import rightArrowShortIconUrl from "../assets/img/right-arrow--short-icon--grey.svg";
import studentImgUrl from "../assets/img/student.jpg";

import Events from "../components/Events/Events";
import { EventsJumbotron } from "../components/Jumbotron/Jumbotron";
import Event from "../components/Event/Event";
import { CardsAboutIndex } from "../components/CustomCards/CustomCards";

import "../scss/eventsPage.styles.scss";

// Queries
import EVENT_QUERY from "../queries/events";

import Query from "../components/Query";

const EventsPage = () => {
  // const data = useStaticQuery(query)
  // console.log(data.allStrapiCatalogoEventos.nodes);
  return (
    <Query query={EVENT_QUERY}>
      {({ data }) => {
        console.log(data);
        return (
          <div style={{ overflowX: "hidden" }}>
            <>
              <EventsJumbotron
                mainTitle={data.evento.Hero.TituloInicial}
                background={data.evento.Hero.Imagen.url}
              />
              <div className="events-page-container">
                <Container>
                  <div className="events-page-container__site-links">
                    <Link to="/">Inicio</Link>
                    <img
                      src={rightArrowShortIconUrl}
                      alt="Ícono de navegación"
                    />
                    <Link to="#" className="active">
                      Eventos
                    </Link>
                  </div>
                  <div className="events-page-container__description">
                    <h5>{data.evento.EventosIntro.Titulo}</h5>
                    <p>{data.evento.EventosIntro.Parrafo1}</p>
                  </div>
                  <Events events={data.catalogoEventos} />
                </Container>
              </div>
              <div className="events-page-secondary-info">
                <Container fluid>
                  <Row>
                    <Col
                      xs={12}
                      lg={6}
                      className="about-us-secondary-info__content"
                    >
                      <h4>{data.evento.EventosInfoSecundaria1.Titulo}</h4>
                      <p>{data.evento.EventosInfoSecundaria1.Descripcion}</p>
                    </Col>
                    <Col
                      xs={12}
                      lg={6}
                      className="about-us-secondary-info__image"
                    >
                      {data.evento.EventosInfoSecundaria1.InfoSecundImagen
                        .url ? (
                        <img
                          src={
                            data.evento.EventosInfoSecundaria1.InfoSecundImagen
                              .url
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </Container>
              </div>
            </>
          </div>
        );
      }}
    </Query>
  );
};

// export const query = graphql`
//   {
//     allStrapiEventos {
//       nodes {
//         Hero {
//           TituloInicial
//           Imagen {
//             url
//           }
//         }
//         EventosIntro {
//           Titulo
//           Parrafo1
//         }

//         EventosInfoSecundaria1 {
//           Titulo
//           Descripcion
//           InfoSecundImagen {
//             url
//           }
//         }
//       }
//     }
//     allStrapiCatalogoEventos {
//       nodes {
//         id
//         NombreEvento
//         Fecha
//         Ubicacion
//         Imagen {
//           url
//         }
//       }
//     }
//   }
// `
export default EventsPage;

// GQL Query eventos
// TarjetasEventos {
//   Titulo
//   Descripcion
// }
