import gql from "graphql-tag";

const MASTERS_QUERY = gql`
  query {
    maestria {
      Hero {
        TituloInicial
        Imagen {
          url
        }
      }
      MaestriasIntro {
        Parrafo1
        Parrafo2
        Subtitulo
        Titulo
      }
      BannerTinto {
        Titulo
        Link
        TextoBoton
        imagen {
          url
        }
      }
      MaestriasTabs {
        Texto
        Titulo
        TituloTab
      }
      ArchivosDescargables {
        Link
        Texto
      }
    }
  }
`;

export default MASTERS_QUERY;
