import React from "react";
// import { Link } from "gatsby"
import { Link } from "react-router-dom";

import "./buttons.styles.scss";

export function WhiteButton({ text, isLink, linkTo }) {
  return isLink ? (
    <Link to={`${linkTo}`} className="btn-white">
      {text}
    </Link>
  ) : (
    <a href="" className="btn-white">
      {text}
    </a>
  );
}

export function BlueButton({ text, icon, altText, linkTo }) {
  return (
    <a href={linkTo} target="_blank" className="btn-blue">
      {text}
      <span>
        <img src={icon} alt={altText} />
      </span>
    </a>
  );
}
export function LocationButton({ text, icon, altText, linkTo }) {
  return (
    <a href={linkTo} className="btn-red" target="_blank">
      <span>{text}</span>
      <img src={icon} alt={altText} />
    </a>
  );
}

export function RedButtonIcon({ text, icon, altText }) {
  return (
    <a href="" className="btn-red">
      <span>{text}</span>
      <img src={icon} alt={altText} />
    </a>
  );
}
