import React from "react";
// import { Link } from "gatsby"
import { Link } from "react-router-dom";

import "./courseCard.styles.scss";

export default function CourseCard({
  key,
  courseImage,
  name,
  code,
  level,
  teacher,
}) {
  return (
    <div className="course-card">
      <img src={courseImage} alt="Imagen del curso" className="img-fluid" />
      <div>
        <h5>{code}</h5>
        <h4>{name}</h4>
        <p>
          Nivel: <span>{level}</span>
        </p>
        <p>
          Docente: <span>{teacher}</span>
        </p>
        <Link to={`/cursos/${code}`} className="btn-red--yellow-border">
          Más detalles
        </Link>
      </div>
    </div>
  );
}
