import React, { useState } from "react";
import Layout from "../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
// import { graphql, Link, useStaticQuery } from "gatsby"
import { Link } from "react-router-dom";

import axios from "axios";

import rightArrowShortIconUrl from "../assets/img/right-arrow--short-icon--grey.svg";
import fbLightBlueIcon from "../assets/img/fb-icon--light-blue.svg";
import igLightBlueIcon from "../assets/img/ig-icon--light-blue.svg";

import "../scss/contact.styles.scss";
import { AboutUsJumbotron } from "../components/Jumbotron/Jumbotron";

// Queries
import CONTACT_QUERY from "../queries/contact";

import Query from "../components/Query";

const initialState = {
  clientName: "",
  email: "",
  phone: "",
  program: "",
  message: "",
};

const ContactPage = () => {
  // const data = useStaticQuery(query);
  const [formData, setFormData] = useState(initialState);
  const [success, setSuccess] = useState("");
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (
      formData.clientName === "" ||
      formData.email === "" ||
      formData.phone === "" ||
      formData.program === "" ||
      formData.message === ""
    ) {
      setSuccess("missing");
      setTimeout(() => {
        setSuccess("");
      }, 5000);
    } else {
      axios
        .post("https://enrjsm-mk.herokuapp.com/email", {
          // to: "rodrigo.zenteno7@gmail.com",
          to: "contacto@enrjsm.edu.mx",
          subject: "Nuevo mensaje de forma de contacto sitio web",
          html: `<h1>${formData.clientName} envió un mensaje </h1> <p>Estos son sus datos de contacto:</p> <ul><li>Correo de contacto: ${formData.email}</li><li>Teléfono: ${formData.phone}</li><li>Está interesado/a en: ${formData.program}</li></ul> <p>Mensaje: ${formData.message}</p>`,
        })
        .then((res) => {
          // console.log(res)
          setSuccess("success");
          setTimeout(() => {
            setSuccess("");
          }, 5000);
        })
        .catch(function (error) {
          setSuccess("error");
          setTimeout(() => {
            setSuccess("");
          }, 5000);
          console.log(error);
        });
      setFormData(initialState);
    }
  };
  return (
    <Query query={CONTACT_QUERY}>
      {({ data: { contacto } }) => {
        console.log(contacto);
        return (
          <div>
            <AboutUsJumbotron
              mainTitle={contacto.Hero.TituloInicial}
              background={contacto.Hero.Imagen.url}
            />
            <section className="contact-us-container">
              <Container>
                <div className="contact-us-container__site-links">
                  <Link to="/">Inicio</Link>
                  <img src={rightArrowShortIconUrl} alt="Ícono de navegación" />
                  <Link to="#" className="active">
                    Contacto
                  </Link>
                </div>
              </Container>
              <Container>
                <Row>
                  <Col xs={12} lg={7}>
                    <div className="contact-us-form-container">
                      <h4>{contacto.ContactoIntro.Titulo}</h4>
                      <p>{contacto.ContactoIntro.Parrafo1}</p>
                      <p>{contacto.ContactoIntro.Subtitulo}</p>
                      <p>{contacto.ContactoIntro.Parrafo2}</p>
                      <small className="m-0 ">
                        * Todos los campos son requeridos
                      </small>
                      <Form>
                        <Form.Group controlId="inputName">
                          <Form.Label>Nombre completo:</Form.Label>
                          <Form.Control
                            type="text"
                            value={formData.clientName}
                            name="clientName"
                            onChange={handleInputChange}
                            required
                          />
                        </Form.Group>
                        <Form.Group controlId="inputEmail">
                          <Form.Label>Correo electrónico:</Form.Label>
                          <Form.Control
                            type="email"
                            value={formData.email}
                            name="email"
                            onChange={handleInputChange}
                            required
                          />
                        </Form.Group>
                        <Form.Group controlId="inputPhone">
                          <Form.Label>Teléfono:</Form.Label>
                          <Form.Control
                            type="number"
                            value={formData.phone}
                            name="phone"
                            onChange={handleInputChange}
                            required
                          />
                        </Form.Group>
                        <Form.Group controlId="selectProgram">
                          <Form.Label>Selecciona oferta educativa:</Form.Label>
                          <Form.Control
                            as="select"
                            value={formData.program}
                            name="program"
                            onChange={handleInputChange}
                            required
                          >
                            <option value="">Selecciona...</option>
                            <option value="Licenciatura en Educación Primaria">
                              Licenciatura en Educación Primaria
                            </option>
                            <option value="Maestría en Formación de Competencias Docentes">
                              Maestría en Formación de Competencias Docentes
                            </option>
                            <option value="Cursos de formación continua">
                              Cursos de formación continua
                            </option>
                          </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="textareaMessage">
                          <Form.Label>Mensaje:</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={formData.message}
                            name="message"
                            onChange={handleInputChange}
                            required
                          />
                        </Form.Group>
                        <input
                          type="submit"
                          value="Enviar"
                          className="btn-blue"
                          onClick={onSubmit}
                        />
                      </Form>
                      <div class="alerts-container my-3">
                        {success === "success" && (
                          <div
                            id="formSuccess2"
                            class="alert alert-success mt-4 mb-2 fixed-top mx-auto text-center"
                            role="alert"
                            style={{ maxWidth: "90vw" }}
                            // style="display: none"
                          >
                            Tu mensaje ha sido enviado correctamente. Pronto un
                            integrante de nuestro equipo se comunicará contigo
                            para atender tu solicitud.
                          </div>
                        )}
                        {success === "error" && (
                          <div
                            id="formDanger2"
                            class="alert alert-danger mt-4 mb-2 fixed-top mx-auto text-center"
                            role="alert"
                            style={{ maxWidth: "90vw" }}
                            // style="display: none"
                          >
                            Hubo un error en el envío de tu solicitud. Por favor
                            intenta más tarde o ponte en contacto con nosotros
                            al teléfono (449) 967 5049
                          </div>
                        )}
                        {success === "missing" && (
                          <div
                            id="formWarning2"
                            class="alert alert-warning mt-4 mb-2"
                            role="alert"
                          >
                            Error con la información ingresada. Verifica todos
                            los campos.
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} lg={5}>
                    <div className="courses-info-container__contact-card">
                      <h4>Información de contacto</h4>
                      <h5>Escuela Normal Rural “Justo Sierra Méndez”</h5>
                      <a
                        href="https://www.google.com/maps/place/Escuela+Normal+Rural+%22Justo+Sierra+M%C3%A9ndez%22/@22.001591,-102.2026659,17z/data=!3m1!4b1!4m5!3m4!1s0x8429fa5b9df2dec1:0xfbaeab62fdb7c67a!8m2!3d22.001591!4d-102.2004772"
                        target="_blank"
                      >
                        Emilio Rangel No. 208 José Ma. Morelos y Pavón.
                        Aguascalientes, CP 20320
                      </a>
                      <a href="tel:4499675049">(449) 967 5049</a>
                      <a href="mailto:contacto@enrjsm.edu.mx">
                        contacto@enrjsm.edu.mx
                      </a>
                      <p>Lun - Vie 9:00 A.M. - 5:00 P.M.</p>
                      <h3>Redes Sociales</h3>
                      <div className="d-flex">
                        {/* <a href="">
                            <img src={waLightBlueIcon} alt="" />
                          </a> */}
                        <a
                          href="https://www.facebook.com/normaljustosierra.oficial"
                          target="_blank"
                        >
                          <img src={fbLightBlueIcon} alt="" />
                        </a>
                        <a
                          href="https://www.instagram.com/normalruraljustosierramendez/"
                          target="_blank"
                        >
                          <img src={igLightBlueIcon} alt="" />
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        );
        // return <p>holi</p>;
      }}
    </Query>
  );
};
// const query = graphql`
//   query {
//     allStrapiContacto {
//       nodes {
//         Hero {
//           TituloInicial
//           Imagen {
//             url
//           }
//         }
//         ContactoIntro {
//           Titulo
//           Parrafo1
//           Parrafo2
//           Subtitulo
//         }
//       }
//     }
//   }
// `

export default ContactPage;
