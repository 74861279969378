import React from "react";

import event1ImageUrl from "../../assets/img/event-1.jpg";
import { BlueButton } from "../Buttons/Buttons";

import fileIconUrl from "../../assets/img/file-icon--white.svg";

import "./post.styles.scss";

export default function Post({ imageUrl, description, button }) {
  const { Link, Texto } = button;
  return (
    <div className="card-post">
      <div className="card-post__header">
        <img
          src={imageUrl}
          className="img-fluid"
          alt="Publicación de la institución"
        />
      </div>
      <div className="card-post__body">
        <p>{description}</p>
        <BlueButton
          text={Texto}
          icon={fileIconUrl}
          altText="Imagen de ícono archivo"
          linkTo={Link}
        />
      </div>
    </div>
  );
}
