import React from "react";
// import { graphql, useStaticQuery } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import "../scss/main.scss";
import "../scss/home.styles.scss";
// import Img from "gatsby-image";

// import Layout from "../components/layout";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import videoPlaceholderUrl from "../assets/img/video-placeholder.jpg";
import linkIconUrl from "../assets/img/link-icon--white.svg";
import rightArrowIconUrl from "../assets/img/right-arrow-icon--white.svg";

import { WhiteButton } from "../components/Buttons/Buttons";
import {
  CardsAboutIndex,
  CardsBgIndex,
} from "../components/CustomCards/CustomCards";
import { VisitBanner } from "../components/Banners/Banners";
// import Events from "../../containers/Events/Events";
import Gallery from "../components/Gallery/Gallery";
import YoutubeEmbed from "../components/YoutubeEmbed/YoutubeEmbed";

// Queries
import HOME_QUERY from "../queries/home";

import Query from "../components/Query";

const IndexPage = () => {
  // const data = useStaticQuery(query);
  // console.log(data.allStrapiHomepage.nodes[0].VideoInstitucionalLink.Link);
  // console.log(data.allStrapiHomepage.nodes[0].LinksNavegacion);
  return (
    <Query query={HOME_QUERY}>
      {({ data: { homepage } }) => {
        console.log(homepage);
        return (
          //   <Layout seo={data.allStrapiHomepage.nodes.seo}>
          <>
            <Jumbotron
              fluid
              className="jumbotron-hero--index"
              style={{
                backgroundImage: `linear-gradient(
                  390deg,
                  rgba(28, 42, 68, .5),
                  rgba(0, 0, 0, 0.2)
                ), url(${homepage.hero.Imagen.url})`,
              }}
            >
              <Container fluid>
                {/* <img src={homepage.hero.Imagen.url} /> */}
                <div className="hero-content">
                  <h1>{homepage.hero.TituloInicial}</h1>
                  <WhiteButton
                    text={"conócenos"}
                    isLink={true}
                    linkTo="/acerca-de"
                  />
                </div>
              </Container>
            </Jumbotron>
            <CardsBgIndex cards={homepage.IndexCards} />
            <section className="about">
              <div className="about__intro">
                <div className="row">
                  <div className="col-12 col-lg-4">
                    <h5>{homepage.AcercaDeIndex.SubTitulo}</h5>
                    <h3>{homepage.AcercaDeIndex.Titulo}</h3>
                  </div>
                  <div className="col-12 col-lg-8">
                    <p>{homepage.AcercaDeIndex.SubTitulo2}</p>
                    <p>{homepage.AcercaDeIndex.Descripcion}</p>
                  </div>
                </div>
              </div>
              <div className="about__cards">
                <CardsAboutIndex
                  cards={homepage.TarjetasAcercaDe}
                  // firstImgUrl={data.file.childImageSharp.fluid}
                />
              </div>
              <div className="about__links">
                <Container fluid>
                  <Row>
                    <Col xs={12} lg={7}>
                      <div className="about__links__video">
                        <div className="about__links__video__header">
                          <h5>{homepage.VideoInstitucionalLink.TituloVideo}</h5>
                          <div className="line-dkbl"></div>
                        </div>
                        <YoutubeEmbed
                          embedId={homepage.VideoInstitucionalLink.Link}
                        />
                      </div>
                      {/* <div className="about__links__notifications">
                        <div className="about__links__notifications__icon">
                          <img src={bellIconUrl} alt="" />
                        </div>
                        <div className="about__links__notifications__body"></div>
                        <div className="about__links__notifications__controls"></div>
                      </div> */}
                    </Col>
                    <Col xs={12} lg={5}>
                      <div className="about__links__container">
                        <div>
                          <img
                            src={linkIconUrl}
                            alt="Más información de la institución"
                          />
                          <h5>Links de navegación</h5>
                        </div>
                        <ul>
                          {homepage.LinksNavegacion.map((link) => (
                            <li>
                              <a href={link.Link} target="_blank">
                                {link.TextoLink}
                              </a>
                            </li>
                          ))}
                          {/* <li>Cursos educativos</li>
                          <li>Link de navegación 2</li>
                          <li>Link de navegación 3</li>
                          <li>Link de navegación 4</li>
                          <li>Link de navegación 5</li>
                          <li>Link de navegación 6</li> */}
                        </ul>
                      </div>
                      <div className="about__links__cta">
                        <div>
                          <a href="tel:4499675049">Contactar admisiones</a>
                          <img
                            src={rightArrowIconUrl}
                            alt="Contactar admisiones"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </section>
            <VisitBanner />
          </>
          // </Layout>
          // <p>hola</p>
        );
      }}
    </Query>
  );
};

export default IndexPage;
