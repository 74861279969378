import gql from "graphql-tag";

const EVENTS_QUERY = gql`
  query {
    evento {
      Hero {
        TituloInicial
        Imagen {
          url
        }
      }
      EventosIntro {
        Titulo
        Parrafo1
      }

      EventosInfoSecundaria1 {
        Titulo
        Descripcion
        InfoSecundImagen {
          url
        }
      }
    }
    catalogoEventos {
      id
      NombreEvento
      Fecha
      Ubicacion
      Imagen {
        url
      }
    }
  }
`;

export default EVENTS_QUERY;
