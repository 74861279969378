import gql from "graphql-tag";

const DEPARTMENTS_QUERY = gql`
  query {
    departamentos {
      id
      Descripcion
      Nombre
      Prioridad
      jefes_departamento {
        Email
        Nombre
        Telefono
      }
    }
    departamentosPage {
      Hero {
        TituloInicial
        Imagen {
          url
        }
      }
      DepartamentosIntro {
        Titulo
        Parrafo1
        Subtitulo
        Parrafo2
      }
    }
    subDepartamentos {
      id
      jefes_departamento {
        Email
        Nombre
        Telefono
      }
      Nombre
      Objetivo
      departamento {
        Nombre
      }
    }
  }
`;

export default DEPARTMENTS_QUERY;

// allStrapiDepartamentosPage {
//   nodes {
//     Hero {
//       TituloInicial
//       Imagen {
//         url
//       }
//     }
//     DepartamentosIntro {
//       Parrafo1
//       Titulo
//       Parrafo2
//       Subtitulo
//     }
//   }
// }
// allStrapiSubDepartamentos {
//   nodes {
//     id
//     jefes_departamento {
//       Email
//       Nombre
//       Telefono
//     }
//     Nombre
//     Objetivo
//     departamento {
//       Nombre
//     }
//   }
// }
