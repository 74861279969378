import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
// import { Link } from "gatsby"
import { Link } from "react-router-dom";

import "./footer.styles.scss";

import logoUrl from "../../assets/img/logo.svg";
import pinIconRedUrl from "../../assets/img/pin-icon--red.svg";
import phoneIconRedUrl from "../../assets/img/phone-icon--red.svg";
import mailIconRedUrl from "../../assets/img/mail-icon--red.svg";
import waIconRedUrl from "../../assets/img/wa-icon--red.svg";
import fbIconRedUrl from "../../assets/img/fb-icon--red.svg";
import igIconRedUrl from "../../assets/img/ig-icon--red.svg";

export default function Footer() {
  return (
    <footer>
      <div className="footer-container">
        <Row>
          <Col xs={12} lg={3}>
            <div className="footer-container__about">
              <img src={logoUrl} alt="ENRJSM logo" />
              <p>Ciencia, Cultura y Trabajo.</p>
            </div>
          </Col>
          <Col xs={6} lg={2}>
            <h5>institución</h5>
            <ul>
              <li>
                <Link to="/eventos">Eventos</Link>
              </li>
              <li>
                <Link to="/departamentos">Organización escolar</Link>
              </li>
              <li>
                <Link to="/acerca-de">Nosotros</Link>
              </li>
            </ul>
          </Col>
          <Col xs={6} lg={2}>
            <h5>oferta educativa</h5>
            <ul>
              <li>
                <Link to="/maestrias">Maestría</Link>
              </li>
              <li>
                <Link to="/licenciaturas">Licenciatura</Link>
              </li>
              <li>
                <Link to="/cursos">Formación continua</Link>
              </li>
            </ul>
          </Col>
          <Col xs={12} lg={3}>
            <h5>contáctanos</h5>
            <div className="footer-container__contact">
              <div className="footer-container__contact__item">
                <div>
                  <img src={pinIconRedUrl} alt="Íconos de contacto" />
                </div>
                <a
                  href="https://www.google.com/maps/place/Escuela+Normal+Rural+%22Justo+Sierra+M%C3%A9ndez%22/@22.001591,-102.2026659,17z/data=!3m1!4b1!4m5!3m4!1s0x8429fa5b9df2dec1:0xfbaeab62fdb7c67a!8m2!3d22.001591!4d-102.2004772"
                  target="_blank"
                >
                  Emilio Rangel No. 208 José Ma. Morelos y Pavón.
                  Aguascalientes, CP 20320
                </a>
              </div>
              <div className="footer-container__contact__item">
                <div>
                  <img src={phoneIconRedUrl} alt="Íconos de contacto" />
                </div>
                <a href="tel:4499675049">(449) 967 5049</a>
              </div>
              <div className="footer-container__contact__item">
                <div>
                  <img src={mailIconRedUrl} alt="Íconos de contacto" />
                </div>
                <a href="mailto:contacto@enrjsm.edu.mx">
                  contacto@enrjsm.edu.mx
                </a>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={2}>
            <h5>social</h5>
            <div className="footer-container__social">
              {/* <a href="">
                <img src={waIconRedUrl} alt="Redes Sociales" />
              </a> */}
              <a
                href="https://www.facebook.com/normaljustosierra.oficial"
                target="_blank"
              >
                <img src={fbIconRedUrl} alt="Redes Sociales" />
              </a>
              <a
                href="https://www.instagram.com/normalruraljustosierramendez/"
                target="_blank"
              >
                <img src={igIconRedUrl} alt="Redes Sociales" />
              </a>
            </div>
          </Col>
        </Row>
      </div>
      <div className="footer-bottom">
        <div>
          <p>
            Todos los derechos reservados. 2022. Escuela Normal Rural "Justo
            Sierra Mendez"
          </p>
        </div>
        <div>
          <p>
            Diseñado y desarrollado por{" "}
            <a href="https://quintech.mx" target="_blank">
              QuinTech
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}
