import React, { useState, useEffect } from "react";

import event1ImageUrl from "../../assets/img/event-1.jpg";
import event2ImageUrl from "../../assets/img/event-2.jpg";
import event3ImageUrl from "../../assets/img/event-3.jpg";

import Event from "../../components/Event/Event";

import "./events.styles.scss";

const eventsArray = [
  {
    eventImageUrl: event1ImageUrl,
    eventName: "Sed ut perspiciatis unde omnis iste",
    eventDate: "11 Ene",
    eventTime: "8:00 am - 10:00 am",
    eventLocation: "Duis aute irure dolor in reprehenderit",
  },
  {
    eventImageUrl: event2ImageUrl,
    eventName: "Sed ut perspiciatis unde omnis iste",
    eventDate: "02 Feb",
    eventTime: "10:00 am - 12:00 pm",
    eventLocation: "Duis aute irure dolor in reprehenderit",
  },
  {
    eventImageUrl: event3ImageUrl,
    eventName: "Sed ut perspiciatis unde omnis iste",
    eventDate: "24 Mar",
    eventTime: "1:00 pm - 2:00 pm",
    eventLocation: "Duis aute irure dolor in reprehenderit",
  },
];
export default function Events({ events }) {
  // const [events, setEvents] = useState([]);
  // useEffect(() => {
  //   setEvents(eventsArray);
  // });
  // console.log(events[0]);
  return (
    <section className="events">
      <div className="events__header">
        <h3>Eventos de la institución</h3>
      </div>
      <div className="events__cards">
        {events.map(
          ({
            // eventImageUrl,
            NombreEvento,
            Fecha,
            Horario,
            Ubicacion,
            Imagen,
          }) => (
            <Event
              imageUrl={Imagen}
              name={NombreEvento}
              date={Fecha}
              time={Horario}
              location={Ubicacion}
            />
          )
        )}
      </div>
      {/* {events.map((event) => (
        <Event
          imageUrl={event.eventImageUrl}
          name={event.eventName}
          date={event.eventDate}
          time={event.eventTime}
          location={event.eventLocation}
        />
      ))} */}
    </section>
  );
}
