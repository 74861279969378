import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { DepartmentCard, SubDepartmentCard } from "../CustomCards/CustomCards";

import "./departmentCollapse.styles.scss";

export default function DepartmentCollapse({ data, subDepartments }) {
  const {
    Nombre: name,
    Descripcion: description,
    jefes_departamento: departmentChief,
  } = data;
  const result = subDepartments.filter(
    (subDepartment) => subDepartment.departamento.Nombre === name
  );
  // console.log(departmentChief);
  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            {name}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <p>{description}</p>
            <div className="card-body__department-manager">
              <DepartmentCard
                chief={departmentChief}
                department={name}
                columns={12}
              />
            </div>
            <div className="card-body__sub-departments">
              <Row>
                {result.map((subDepartment) => (
                  <SubDepartmentCard
                    key={subDepartment.id}
                    data={subDepartment}
                    columns={6}
                  />
                ))}
              </Row>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}
